import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import { MultiCheck } from "../../../ui/multi_check/Component.js";
import Select from "react-select";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh, data = "", view }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const [amenities, setAmenities] = useState([]);
  // const [areas, setAreas] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);

  const [formData, setFormData] = useState(initialFormData);
  const [mode, setMode] = useState(view);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingError, setIsSubmittingError] = useState(false);

  const [selectValues, setSelectValues] = useState({
    // area: "",
    property_type: "",
  });

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  let propertyId = data;

  // useEffect(() => {
  //   const area = areas?.find((item) => item._id === formData.area) || "";
  //   setSelectValues((prev) => ({
  //     ...prev,
  //     area: { label: area.area_name, value: area._id },
  //   }));
  // }, [formData.area, areas]);

  useEffect(() => {
    const selectedType = propertyTypes?.find((item) => item._id === formData.property_type);
    setSelectValues((prev) => ({
      ...prev,
      property_type: selectedType ? { label: selectedType.name, value: selectedType._id } : "",
    }));
  }, [formData.property_type, propertyTypes]);

  // to fetch masters data
  const fetchData = async () => {
    try {
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        urlName: hotelId,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.property_amenity_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      // const areasResponse = await fetch(url + clientpoints.area_view, {
      //   method: "POST",
      //   headers: pageHeaders,
      //   credentials: "include",
      //   body: JSON.stringify(requestBody),
      // });

      const propertyTypesResponse = await fetch(url + clientpoints.property_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok || !propertyTypesResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const amenitiesData = await response.json();
      // const areasData = await areasResponse.json();
      const propertyTypesData = await propertyTypesResponse.json();

      setAmenities(amenitiesData.Data);
      // setAreas(areasData.Data);
      setPropertyTypes(propertyTypesData.Data);

      setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // for edit modal ---------------------------------------------------------------------------------
  useEffect(() => {
    if (isModalOpen && propertyId && (view === "edit" || view === "view")) {
      setLoading(true);
      fetchPropertyData(propertyId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, propertyId, view]);

  const fetchPropertyData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        console.log("property dataaaa", response_data);

        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          ...response_data,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  // ----------------------------------------------------------------



  const handleChange = (event) => {
    const { name, value } = event.target;
    
    let formattedValue = value;

    if (name === 'property_url') {
      formattedValue = value.replace(/\s+/g, '-').toLowerCase();  // Replace spaces with hyphens and convert to lowercase
    }
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSetValue = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeObjectInput = (e, type) => {
    setFormData((prev) => ({
      ...prev,
      [type]: { ...prev[type], [e.target.name]: e.target.value },
    }));
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    
    if (!formData.title.trim()) {
      errors.title = "Name is required";
      isValid = false;
    }

    // if (!formData.property_url.trim()) {
    //   errors.property_url = "Property URL is required";
    //   isValid = false;
    // }

    if (!formData.property_url.trim()) {
      errors.property_url = "Property URL is required";
      isValid = false;
    } else if (/[^a-zA-Z0-9]/.test(formData.property_url.trim())) {
      errors.property_url = "Property URL should only contain letters and numbers (no special characters like '-')";
      isValid = false;
    }

    if (!formData.contact_email.trim()) {
      errors.contact_email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.contact_email)) {
      errors.contact_email = "Invalid email address";
      isValid = false;
    }
    if (!formData.contact_number.trim()) {
      errors.contact_number = "Phone is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.contact_number)) {
      errors.contact_number = "Phone number must be 10 digits";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const submitFormData = async () => {
    let apiEndpoint = "";
    let filters = {};

    let requestBody = {};

    if (view === "add") {
      apiEndpoint = clientpoints.property_add;
      requestBody = { urlName: hotelId, ...formData };
    } else if (view === "edit") {
      apiEndpoint = clientpoints.property_edit;
      filters = { _id: propertyId };
      requestBody = {
        filters: filters,
        urlName: hotelId,
        data: { ...formData },
      };
    }

    try {
      const response = await fetch(url + apiEndpoint, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.Status) {
        toast.success(responseData.Message);
        setFormData(initialFormData);
      } else {
        toast.error(responseData.Message);
      }

      onRefresh();
      setIsModalOpen(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (validateForm()) {
      try {
        
        // const slugResponse = await fetch(url + clientpoints.property_view, {
        //   method: "POST",
        //   headers: pageHeaders,
        //   credentials: "include",
        //   body: JSON.stringify({ urlName: hotelId, filters: { slug: formData.slug, delete_status: "", _id: { $ne: propertyId ? propertyId : "" } } }),
        // });

        // const slugData = await slugResponse.json();

        // if (slugData.Status && slugData.Data.length > 0) {
        //   if (slugData.Data[0].slug === formData.slug) {
        //     toast.error("Slug already in use for other property.");
        //     return;
        //   }
        // }

        await submitFormData();
      } catch (error) {
        toast.error("Failed to submit form data.", error);
        console.log("Error", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  const checkExistsInDataBase = async (key, value, msg, table) => {
    try {
      const response = await fetch(url + clientpoints[table], {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId, filters: { [key]: value, delete_status: { $in: [true, false] } } }),
        // body: JSON.stringify({ urlName: hotelId, filters: { [key]: value, delete_status: { $in: [true, false] }, _id: { $ne: propertyId ? propertyId : "" } } }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.Count >= 1) {
        toast.warning(`${msg} exists!`);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast.error("Error");
      return true;
    }
  };

  const handleSlugBlur = async () => {
    const slug = formData.slug.trim();
    if (slug) {
      const exists = await checkExistsInDataBase("slug", slug, "Slug", "property_count");
      if (exists) {
        setErrors({ ...errors, slug: "Slug Name already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, slug: "" });
        setIsSubmittingError(false);
      }
    }
  };

  const handleURLBlur = async () => {
    const property_url = formData.property_url.trim();
    if (property_url) {
      const exists = await checkExistsInDataBase("property_url", property_url, "Property URL", "property_count");
      if (exists) {
        setErrors({ ...errors, property_url: "Property URL Name already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, slug: "" });
        setIsSubmittingError(false);
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {mode === "view" && `${modalName} (View)`}
                  {mode === "edit" && `${modalName} (Edit)`}
                  {mode === "add" && `${modalName} (Add)`}
                </h5>
                {mode === "view" && (
                  <button className="ms-auto btn btn-primary " onClick={() => setMode("edit")}>
                    <i class="ri-edit-box-line"></i>
                  </button>
                )}
                <button type="button" className="btn btn-danger" onClick={handleCloseModal} style={{ marginLeft: "8px" }}>
                  <i class="ri-close-line"></i>
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body" style={{ padding: "20px 0 0 0", maxHeight: "90vh" }}>

                    <div className="" style={{ maxHeight: "80vh", overflowY: "scroll", padding: "0 15px" }}>
                      
                      {/* --------------------------------------------------------------------- */}
                      
                        <div className="row">
                        
                          {/* PROPERTY TYPE-------------------------------- */}

                          <div className="col-md-6">
                            <label htmlFor="property_type" className="col-form-label">Property Type</label>
                            <Select
                              isDisabled={mode === "view"}
                              required options={propertyTypes?.map((type) => ({ label: type.name, value: type._id }))}
                              value={selectValues.property_type}
                              onChange={(val) => setFormData((prev) => ({ ...prev, property_type: val.value, }))}
                              placeholder="Select Property Type..."
                              className="flex-1 w-full"
                              // styles={customStyles}
                              // menuPortalTarget={document.body}
                            />
                            {errors.property_type && <div className="invalid-feedback">{errors.property_type}</div>}
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="title" className="col-form-label">Name</label>
                            <input
                              disabled={mode === "view"} type="text" className={`form-control ${errors.title ? "is-invalid" : ""}`}
                              name="title" id="title" value={formData.title} onChange={handleChange} 
                            />
                            {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                          </div>
                        

                          {/* 
                          <div className="col-md-6">
                            <label htmlFor="slug" className="col-form-label">Slug</label>
                            <input
                              disabled={mode === "view"} type="text" className={`form-control ${errors.slug ? "is-invalid" : ""}`}
                              name="slug" id="slug" value={formData.slug} onChange={handleChange}
                            />
                            {errors.slug && <div className="invalid-feedback">{errors.slug}</div>}
                          </div>
                          */}

                          <div className="col-md-12">
                            <label htmlFor="short_description" className="col-form-label">Short Description</label>
                            <textarea
                              disabled={mode === "view"} rows={4} type="text" className={`form-control ${errors.short_description ? "is-invalid" : ""}`}
                              name="short_description" id="short_description" value={formData.short_description} onChange={handleChange}
                            />
                            {errors.short_description && <div className="invalid-feedback">{errors.short_description}</div>}
                          </div>

                          <div className="col-md-12">
                            <label htmlFor="long_description" className="col-form-label">Long Description</label>
                            <textarea
                              disabled={mode === "view"} rows={4} type="text"
                              className={`form-control ${errors.long_description ? "is-invalid" : ""}`}
                              name="long_description" id="long_description" value={formData.long_description} onChange={handleChange}
                            />
                            {errors.long_description && <div className="invalid-feedback">{errors.long_description}</div>}
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="contact_email" className="col-form-label">Contact Email</label>
                            <input
                              disabled={mode === "view"} type="text"
                              className={`form-control ${errors.contact_email ? "is-invalid" : ""}`}
                              id="contact_email" name="contact_email" value={formData.contact_email} onChange={handleChange}
                            />
                            {errors.contact_email && <div className="invalid-feedback">{errors.contact_email}</div>}
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="contact_number" className="col-form-label">Contact Number</label>
                            <input
                              disabled={mode === "view"} type="text"
                              className={`form-control ${errors.contact_number ? "is-invalid" : ""}`}
                              id="contact_number" name="contact_number" value={formData.contact_number} onChange={handleChange}
                            />
                            {errors.contact_number && <div className="invalid-feedback">{errors.contact_number}</div>}
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="property_url" className="col-form-label">URL</label>
                            <input
                              disabled={mode === "view" || mode === "edit"} type="text" className={`form-control ${errors.property_url ? "is-invalid" : ""}`}
                              name="property_url" id="property_url" value={formData.property_url} onChange={handleChange} onBlur={handleURLBlur}
                            />
                            {errors.property_url && <div className="invalid-feedback">{errors.property_url}</div>}
                          </div>                          

                          <div className="col-md-6">
                            <label htmlFor="minimum_stay" className="col-form-label">Minimum Stay</label>
                            <input
                              disabled={mode === "view"} type="number"
                              className={`form-control ${errors.minimum_stay ? "is-invalid" : ""}`}
                              id="minimum_stay" name="minimum_stay" value={formData.minimum_stay} onChange={handleChange}
                            />
                            {errors.minimum_stay && <div className="invalid-feedback">{errors.minimum_stay}</div>}
                          </div>


                          <div className="col-md-6">
                            <label htmlFor="property_address" className="col-form-label">Property Address</label>
                            <input
                              disabled={mode === "view"} type="text" className={`form-control `} id="property_address"
                              name="property_address" value={formData.property_address} onChange={handleChange}
                            />
                          </div>{" "}

                          <div className="col-md-6">
                            <label htmlFor="country" className="col-form-label">Country</label>
                            <input disabled={mode === "view"} type="text" className={`form-control `} id="country" name="country" value={formData.country} onChange={handleChange} />
                          </div>{" "}

                          <div className="col-md-6">
                            <label htmlFor="state" className="col-form-label">State</label>
                            <input disabled={mode === "view"} type="text" className={`form-control `} id="state" name="state" value={formData.state} onChange={handleChange} />
                          </div>{" "}

                          <div className="col-md-6">
                            <label htmlFor="city" className="col-form-label">City</label>
                            <input disabled={mode === "view"} type="text" className={`form-control `} id="city" name="city" value={formData.city} onChange={handleChange} />
                          </div>{" "}

                          <div className="col-md-6">
                            <label htmlFor="area" className="col-form-label">Area</label>
                            <input disabled={mode === "view"} type="text" className={`form-control`} id="area" name="area" value={formData.area} onChange={handleChange} 
                            />
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="pincode" className="col-form-label">Pincode</label>
                            <input disabled={mode === "view"} type="text" className={`form-control `} id="pincode" name="pincode" value={formData.pincode} onChange={handleChange} />
                          </div>{" "}

                          <div className="col-md-6">
                            <label htmlFor="checkin_time" className="col-form-label">
                              Check-in Time
                            </label>
                            <input disabled={mode === "view"} type="time" className={`form-control `} id="checkin_time" name="checkin_time" value={formData.checkin_time} onChange={handleChange} />
                          </div>{" "}

                          <div className="col-md-6">
                            <label htmlFor="checkout_time" className="col-form-label">
                              Check-out time
                            </label>
                            <input disabled={mode === "view"} type="time" className={`form-control `} id="checkout_time" name="checkout_time" value={formData.checkout_time} onChange={handleChange} />
                          </div>{" "}

                          
                          <div className="col-md-12 mb-3">
                            <MultiCheck
                              disabled={mode === "view"} label="Property Amenities" options={amenities} selected={formData.amenities}
                              name="amenities" onCheck={handleSetValue} optionType="object"
                            />
                          </div>
                          
                        </div>

                      <div className="modal-footer d-flex justify-content-between align-items-center">
                        {date ? (<p className="float-start">CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>):(<p className="float-start"></p>)}
                        <div className="d-flex gap-2">
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting || isSubmittingError}>
                              {isSubmitting ? "Loading..." : "Submit"}
                            </button>
                        </div>
                      </div>

                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const initialFormData = {
  title: "",
  short_description: "",
  long_description: "",
  contact_email: "",
  contact_number: "",
  minimum_stay: "",
  property_type: "",
  property_url: "",
  property_address: "",
  country: "",
  state: "",
  city :"",
  area: "",
  pincode: "",
  checkin_time: "",
  checkout_time: "",
  amenities: [],
  images: [],
};

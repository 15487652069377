import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./component.css";
import TogglePill from "../../../ui/TogglePill.js";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const initialFormData = {
    name: "",
    // image: "",
    meta_data: {
      title: "",
      description: "",
      keywords: "",
    },
    suffix: "",
    prefix: "",
    increment_type: "Numeric",
    isActive: false,
    isFeatured: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleChangeMetaInput = (e) => {
    setFormData((prev) => ({
      ...prev,
      meta_data: { ...prev.meta_data, [e.target.name]: e.target.value },
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    // if (!formData.prefix.trim()) {
    //   errors.prefix = "Prefix is required";
    //   isValid = false;
    // }

    // if (!formData.suffix.trim()) {
    //   errors.suffix = "Suffix is required";
    //   isValid = false;
    // }

    if (!formData.increment_type.trim()) {
      errors.increment_type = "Increment type is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.room_type_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            name: formData.name,
            meta_data: {
              title: formData.meta_data.title,
              description: formData.meta_data.description,
              keywords: formData.meta_data.keywords,
            },
            suffix: formData.suffix,
            prefix: formData.prefix,
            increment_type: formData.increment_type,
            isActive: formData.isActive,
            isFeatured: formData.isFeatured,
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);

          setFormData(initialFormData);
          setIsModalOpen(false);
        } else {
          toast.error(responseData.Message);
          setIsModalOpen(false);
        }
        onRefresh();
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setFormData(initialFormData);
  };
  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body" style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="name" className="col-form-label">
                        Name
                      </label>
                      <input type="text" className={`form-control ${errors.name ? "is-invalid" : ""}`} id="name" name="name" value={formData.name} onChange={handleChange} />
                      {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>

                    {/* <div className="col-md-6">
                      <label htmlFor="title" className="col-form-label">
                        Meta Title
                      </label>
                      <input
                        type="text"
                        className={`form-control `}
                        id="title"
                        name="title"
                        value={formData.meta_data.title}
                        onChange={handleChangeMetaInput}
                      />
                    </div> */}

                    {/* <div className="col-md-6">
                      <label htmlFor="description" className="col-form-label">
                        Meta Description
                      </label>
                      <input
                        type="text"
                        className={`form-control `}
                        id="description"
                        name="description"
                        value={formData.meta_data.description}
                        onChange={handleChangeMetaInput}
                      />
                    </div> */}

                    {/* <div className="col-md-6">
                      <label htmlFor="keywords" className="col-form-label">
                        Meta Keywords
                      </label>
                      <input
                        type="text"
                        className={`form-control `}
                        id="keywords"
                        name="keywords"
                        value={formData.meta_data.keywords}
                        onChange={handleChangeMetaInput}
                      />
                    </div> */}

                    <div className="col-md-6">
                      <label htmlFor="prefix" className="col-form-label">
                        Room Number Prefix
                      </label>
                      <input placeholder="4" type="text" className={`form-control ${errors.prefix ? "is-invalid" : ""}`} id="prefix" name="prefix" value={formData.prefix} onChange={handleChange} />
                      {errors.prefix && <div className="invalid-feedback">{errors.prefix}</div>}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="suffix" className="col-form-label">
                        Room Number Suffix
                      </label>
                      <input placeholder="A" type="text" className={`form-control ${errors.suffix ? "is-invalid" : ""}`} id="suffix" name="suffix" value={formData.suffix} onChange={handleChange} />
                      {errors.suffix && <div className="invalid-feedback">{errors.suffix}</div>}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="increment_type" className="col-form-label">
                        Type
                      </label>
                      <select
                        id="increment_type"
                        className={`form-select ${errors.increment_type ? "is-invalid" : ""}`}
                        value={formData.increment_type}
                        onChange={(e) => setFormData((prev) => ({ ...prev, increment_type: e.target.value }))}
                      >
                        <option value="Numeric">Numeric</option>
                        <option value="Alphabet">Alphabet</option>
                      </select>
                      {errors.increment_type && <div className="invalid-feedback">{errors.increment_type}</div>}
                    </div>

                    {/* <div className="d-flex align-items-center gap-3">
                      <label>
                        <input
                          type="checkbox"
                          name="isFeatured"
                          checked={formData.isFeatured}
                          onChange={(e) => setFormData((prev) => ({ ...prev, isFeatured: e.target.checked }))}
                        />
                      </label>
                       Featured
                    </div> */}

                    {/* <div className="d-flex align-items-center gap-3">
                      <label>
                        <input type="checkbox" name="isActive" checked={formData.isActive} onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))} />
                      </label>
                      Active
                    </div> */}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

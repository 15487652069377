import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import SearchableSelectComponentProperty from "../property_select/Component.js";
import SearchableSelectComponentUser from "../user_select/Component.js";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, view = "view" }) {
  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const [mode, setMode] = useState(view);

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [propertyName, setPropertyName] = useState("");
  const [userName, setUserName] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    check_in: "",
    check_out: "",
    // reason: "",
    description: "",
  });

  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  const [searchValueUser, setSearchValueUser] = useState("");
  const [suggestionsUser, setSuggestionsUser] = useState([]);
  const [showDropdownUser, setShowDropdownUser] = useState("");

  // useEffect(() => {
  //   fetchClientData();
  // }, []);

  // const fetchClientData = async () => {
  //   try {
  //     const response = await fetch(url + clientpoints.property_view, {
  //       method: "POST",
  //       headers: pageHeaders,
  //       body: JSON.stringify({ filters: { hotel_id: hotelId } }),
  //       credentials: "include",
  //     });
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch client data");
  //     }
  //     const data = await response.json();
  //     setPropertyList(data.Data);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let visitorId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!propertyName.trim()) {
      errors.property = "Property is required";
      isValid = false;
    }
    if (!userName.trim()) {
      errors.user = "User is required";
      isValid = false;
    }

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email.trim()) {
      errors.name = "Email is required";
      isValid = false;
    }
    if (!formData.phone.trim()) {
      errors.name = "Phone is required";
      isValid = false;
    }
    if (!formData.check_in.trim()) {
      errors.check_in = "Check In is required";
      isValid = false;
    }
    // if (!formData.reason.trim()) {
    //   errors.reason = "Reason is required";
    //   isValid = false;
    // }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isModalOpen && visitorId) {
      setLoading(true);
      fetchData(visitorId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, visitorId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.visitor_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }

      const data = await response.json();

      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setPropertyName(response_data.property_id._id);
        setUserName(response_data.user_id._id);
        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          name: response_data.name,
          email: response_data.email,
          phone: response_data.phone,
          check_in: response_data.check_in,
          check_out: response_data.check_out,
          // reason: response_data.reason,
          description: response_data.description,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.visitor_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: current_id },
            data: {
              property_id: propertyName,
              user_id: userName,
              name: formData.name,
              email: formData.email,
              phone: formData.phone,
              check_in: formData.check_in,
              check_out: formData.check_out,
              // reason: formData.reason,
              description: formData.description,
            },
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" ? `View ${modalName}` : `Edit ${modalName}`}
                </h5>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary ' onClick={() => setMode("edit")}>
                <i class="ri-edit-box-line"></i>
                </button>
              )}
                <button type='button' className='btn btn-danger' onClick={handleCloseModal} style={{marginLeft:"8px"}}>
                 <i class="ri-close-line"></i>
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body'style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                    <div>
                      <label htmlFor='property' className='col-form-label'>
                        Select Property
                      </label>
                      <SearchableSelectComponentProperty
                        disabled={mode === "view"}
                        propertyName={propertyName}
                        setPropertyName={setPropertyName}
                        searchValueProps={{ searchValue, setSearchValue }}
                        suggestionsProps={{ suggestions, setSuggestions }}
                        showDropdownProps={{ showDropdown, setShowDropdown }}
                        form={false}
                        view='edit'
                      />
                      {errors.property && <div className='invalid-feedback'>{errors.property}</div>}
                    </div>

                    <div>
                      <label htmlFor='user' className='col-form-label'>
                        Select User
                      </label>
                      <SearchableSelectComponentUser
                        disabled={mode === "view"}
                        userName={userName}
                        setUserName={setUserName}
                        searchValueProps={{ searchValueUser, setSearchValueUser }}
                        suggestionsProps={{ suggestionsUser, setSuggestionsUser }}
                        showDropdownProps={{ showDropdownUser, setShowDropdownUser }}
                        form={false}
                        view='edit'
                      />
                      {errors.user && <div className='invalid-feedback'>{errors.user}</div>}
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <label htmlFor='name' className='col-form-label'>
                          Name
                        </label>
                        <input disabled={mode === "view"} type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                        {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='email' className='col-form-label'>
                          Email
                        </label>
                        <input disabled={mode === "view"} type='email' className={`form-control ${errors.email ? "is-invalid" : ""}`} id='email' name='email' value={formData.email} onChange={handleChange} />
                        {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='phone' className='col-form-label'>
                          Phone
                        </label>
                        <input disabled={mode === "view"} type='text' className={`form-control ${errors.phone ? "is-invalid" : ""}`} id='phone' name='phone' value={formData.phone} maxLength={10} pattern='[0-9]*' onChange={handleChange} />
                        {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='check_in' className='col-form-label'>
                          Check-in Date
                        </label>
                        <input disabled={mode === "view"} type='datetime-local' className={`form-control ${errors.check_in ? "is-invalid" : ""}`} id='check_in' name='check_in' value={formData.check_in} onChange={handleChange} />
                        {errors.check_in && <div className='invalid-feedback'>{errors.check_in}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='check_out' className='col-form-label'>
                          Check-out Date
                        </label>
                        <input disabled={mode === "view"} type='datetime-local' className={`form-control ${errors.check_out ? "is-invalid" : ""}`} id='check_out' name='check_out' value={formData.check_out} onChange={handleChange} />
                        {errors.check_out && <div className='invalid-feedback'>{errors.check_out}</div>}
                      </div>

                      {/* <div className='col-md-6'>
                        <label htmlFor='reason' className='col-form-label'>
                          Reason
                        </label>
                        <input type='text' className={`form-control ${errors.reason ? "is-invalid" : ""}`} id='reason' name='reason' value={formData.reason} onChange={handleChange} />
                        {errors.reason && <div className='invalid-feedback'>{errors.reason}</div>}
                      </div> */}

                      <div className='col-md-12'>
                        <label htmlFor='description' className='col-form-label'>
                          Description
                        </label>
                        <textarea disabled={mode === "view"} className={`form-control ${errors.description ? "is-invalid" : ""}`} id='description' name='description' value={formData.description} onChange={handleChange} />
                        {errors.description && <div className='invalid-feedback'>{errors.description}</div>}
                      </div>
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    {mode !== "view" && (
                      <>
                        {/* <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                          Close
                        </button> */}
                        {/* <button type="submit" className="btn btn-primary">Update</button> */}
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                          {isSubmitting ? "Loading..." : "Update"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

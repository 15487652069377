import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix, domain_name } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { QRCodeCanvas } from 'qrcode.react';
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import Select from "react-select";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const [amenities, setAmenities] = useState([]);
  const [properties, setProperties] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [pricingTypes, setPricingTypes] = useState([]);

  const [formData, setFormData] = useState(initialFormData);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectValues, setSelectValues] = useState({
    property_id: "",
    room_type: "",
  });

  const [generatedUrlStayStation, setGeneratedUrlStayStation] = useState("");
  const [generatedUrlCustomerDomain, setGeneratedUrlCustomerDomain] = useState("");

  const [stayDomainCondition, setStayDomainCondition] = useState(true);
  const [customDomainCondition, setCustomDomainCondition] = useState(true);

  const [ourPromptError, setOurPromptError] = useState("");

  const handleSelectChange = (val) => {
    setGeneratedUrlStayStation('');
    setGeneratedUrlCustomerDomain('');
    setStayDomainCondition(true);
    setCustomDomainCondition(true);
    setOurPromptError('');

    setSelectValues(val);
    const newUrlStaystation = `${domain_name}/hotel/${hotelId}/feedback/${val.url}`;
    const newUrlCustomerDomain = `${val.hotelDomain}/feedback/${val.url}`;
    
    if(val.url ==  undefined){
      setStayDomainCondition(false);
      setOurPromptError("Please add property URL in property details");
      alert("Please add property URL in property details");
    }

    if(val.hotelDomain ==  undefined){ setCustomDomainCondition(false); }

    setGeneratedUrlStayStation(newUrlStaystation);
    setGeneratedUrlCustomerDomain(newUrlCustomerDomain);
  };

  const handleCopyClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(generatedUrlStayStation)
      .then(() => alert("URL copied to clipboard!"))
      .catch((error) => alert("Failed to copy staystation URL: " + error));
  };

  const handleCopyCustomerDomain = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(generatedUrlCustomerDomain)
      .then(() => alert("URL copied to clipboard!"))
      .catch((error) => alert("Failed to copy Custom URL: " + error));
  };

  useEffect(() => {
    const property = properties?.find((item) => item._id === formData.property_id) || "";
    setSelectValues((prev) => ({
      ...prev,
      property_id: { label: property.title, value: property._id, hotelDomain: property?.hotel_id?.domain },
    }));
  }, [formData.property_id, properties]);


  // to fetch masters data
  const fetchData = async () => {
    try {
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        urlName: hotelId,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.apartment_amenity_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const propertiesResponse = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const roomTypesResponse = await fetch(url + clientpoints.room_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      const pricingTypesResponse = await fetch(url + clientpoints.pricing_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok || !propertiesResponse.ok || !roomTypesResponse.ok || !pricingTypesResponse) {
        throw new Error("Network response was not ok");
      }

      const amenitiesData = await response.json();
      const propertiesData = await propertiesResponse.json();
      const roomTypesData = await roomTypesResponse.json();
      const pricingTypesData = await pricingTypesResponse.json();

      setAmenities(amenitiesData.Data);
      setProperties(propertiesData.Data);
      setRoomTypes(roomTypesData.Data);
      setPricingTypes(pricingTypesData.Data);

      setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  const [names, setNames] = useState([]);

  const generateNames = ({ num_of_apartments, prefix, suffix, increment_type }) => {
    return Array.from({ length: Number(num_of_apartments) || 1 }, (_, index) => {
      let incrementalValue;

      if (increment_type === "Numeric") {
        incrementalValue = index + 1;
      } else if (increment_type === "Alphabet") {
        const place = index + 1;
        if (place <= 26) {
          incrementalValue = String.fromCharCode(64 + place);
        } else {
          const firstLetter = String.fromCharCode(64 + Math.floor((place - 1) / 26));
          const secondLetter = String.fromCharCode(64 + ((place - 1) % 26) + 1);
          incrementalValue = firstLetter + secondLetter;
        }
      }

      return `${prefix}${incrementalValue}${suffix}`;
    });
  };

  useEffect(() => {
    if (formData.room_type) {
      setNames(generateNames(formData));
    }
  }, [formData.room_type, formData.prefix, formData.suffix, formData.increment_type]);

  // const handleBlur = () => {
  //   setNames(generateNames(formData));
  // };

  const handleBlur = () => {
    setNames((prev) => {
      const newLength = Number(formData.num_of_apartments) || 1;
      const currentNames = [...prev];

      if (newLength > currentNames.length) {
        // Add new names for additional fields
        const additionalNames = Array.from({ length: newLength - currentNames.length }, (_, index) => {
          let incrementalValue;
          const baseIndex = currentNames.length + index;

          if (formData.increment_type === "Numeric") {
            incrementalValue = baseIndex + 1;
          } else if (formData.increment_type === "Alphabet") {
            const place = baseIndex + 1;
            if (place <= 26) {
              incrementalValue = String.fromCharCode(64 + place);
            } else {
              const firstLetter = String.fromCharCode(64 + Math.floor((place - 1) / 26));
              const secondLetter = String.fromCharCode(64 + ((place - 1) % 26) + 1);
              incrementalValue = firstLetter + secondLetter;
            }
          }

          return `${formData.prefix}${incrementalValue}${formData.suffix}`;
        });
        return [...currentNames, ...additionalNames];
      } else if (newLength < currentNames.length) {
        // Remove extra names from the end
        return currentNames.slice(0, newLength);
      }
      return currentNames; // If length is the same, keep as is
    });
  };

  const handleNameChange = (e, index) => {
    const { value } = e.target;
    setNames((prev) => prev.map((item, i) => (i === index ? value : item)));
  };

  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>

              <form>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body" style={{ maxHeight: "70vh", overflowY: "scroll", padding: "0 15px" }}>
                    <div className="">
                      {/* ------------------------------------------------------------------- */}

                      {/* --------------------------------------------------------------------- */}
                      <div className="row">
                        {/* PROPERTY TYPE-------------------------------- */}
                        <div className="col-md-12 mt-4 mb-4">
                          <label htmlFor="property_id" className="col-form-label">
                            Select Property Name
                          </label>
                          <Select
                            required
                            options={properties?.map((property) => ({ label: `${property.title}`, value: property._id, url: property.property_url, hotelDomain: property.hotel_id.domain }))}
                            value={selectValues.property_id}
                            onChange={(val) =>{
                              setFormData((prev) => ({
                                ...prev,
                                property_id: val.value,
                              }));
                              handleSelectChange(val);
                              }
                            }
                            placeholder="Select Property..."
                          />
                          {errors.property_id && <div className="invalid-feedback">{errors.property_id}</div>}
                        </div>
                      </div>


                        {ourPromptError && (
                          <>
                          <p className="text-danger">{ourPromptError}</p>
                          </>
                        )}
                        {generatedUrlStayStation && stayDomainCondition && (
                          <>
                          <div className="row p-4">
                            <div className="col-md-12 mt-4 p-0">
                              <p><strong>Staystation URL & QR Code:</strong></p>
                              <p>{generatedUrlStayStation}</p>
                            </div>
                            <div className="col-md-12 ">
                              <center>
                              <QRCodeCanvas value={generatedUrlStayStation} size={128} level="H" />
                              </center>
                            </div>
                            <button className="btn btn-primary" onClick={handleCopyClick}>Copy Staystation URL</button>
                            </div>

                            <hr/>
                          </>
                        )}
                        {generatedUrlCustomerDomain && customDomainCondition && stayDomainCondition && (
                          <>
                          <div className="row p-4">
                            <div className="col-md-12 mt-4 p-0">
                              <p><strong>Customer URL & QR Code:</strong></p>
                              <p>{generatedUrlCustomerDomain}</p>
                            </div>
                            <div className="col-md-12 ">
                              <center>
                              <QRCodeCanvas value={generatedUrlCustomerDomain} size={128} level="H" />
                              </center>
                            </div>
                            <button className="btn btn-primary" onClick={handleCopyCustomerDomain}>Copy Custom URL</button>
                            </div>
                          </>
                        )}

                        {/* <div className="modal-footer"></div> */}
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const initialFormData = {
  property_id: "",
};

import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./component.css";
import useHotelId from "../../../../../../lib/useHotelId.js";

const formulaObj = { value: "", type: "percentage", operator: "+" };
const extraPriceFormulaObj = { threshold: "", value: "", type: "percentage" };

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const [formData, setFormData] = useState({
    name: "",
    color: "#000000",
    description: "",
    isActive: false,
    isSingleVariant: true,
  });

  const [additionalFields, setAdditionalFields] = useState([
    { name: "", description: "", formula: { ...formulaObj }, extra_adult_formula: { ...extraPriceFormulaObj }, extra_child_formula: { ...extraPriceFormulaObj } },
  ]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "name") {
      if (formData.isSingleVariant) {
        setAdditionalFields((prev) => {
          const updatedFields = [...prev];
          if (updatedFields.length > 0) {
            updatedFields[0] = { ...updatedFields[0], name: value };
          }
          return updatedFields;
        });

        setErrors((prevErrors) => ({
          ...prevErrors,
          [`additionalFieldName${0}`]: "",
        }));
      }
    }
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (additionalFields.length === 0) {
      errors.variant = "Add at least one variant!";
      isValid = false;
    } else {
      additionalFields.forEach((field, index) => {
        if (!field.name.trim()) {
          errors[`additionalFieldName${index}`] = "Name is required for variant";
          isValid = false;
        }

        if (field.formula.value === "" || isNaN(Number(field.formula.value))) {
          errors[`additionalFieldFormula${index}.value`] = "Value is required";
          isValid = false;
        }
        if (!["percentage", "fixed"].includes(field.formula.type)) {
          errors[`additionalFieldFormula${index}.type`] = "Type must be either 'percentage' or 'fixed'";
          isValid = false;
        }
        if (!["+", "-", "*", "/"].includes(field.formula.operator)) {
          errors[`additionalFieldFormula${index}.operator`] = "Operator must be one of '+', '-', '*', or '/'";
          isValid = false;
        }

        if (field.extra_adult_formula.value === "" || isNaN(Number(field.extra_adult_formula.value))) {
          errors[`additionalFieldExtraFormulaAdult${index}.value`] = "Value is required";
          isValid = false;
        }
        if (field.extra_adult_formula.threshold === "" || isNaN(Number(field.extra_adult_formula.threshold))) {
          errors[`additionalFieldExtraFormulaAdult${index}.threshold`] = "Threshold is required";
          isValid = false;
        }
        if (!["percentage", "fixed"].includes(field.extra_adult_formula.type)) {
          errors[`additionalFieldExtraFormulaAdult${index}.type`] = "Type must be either 'percentage' or 'fixed'";
          isValid = false;
        }

        if (field.extra_child_formula.value === "" || isNaN(Number(field.extra_child_formula.value))) {
          errors[`additionalFieldExtraFormulaChild${index}.value`] = "Value is required";
          isValid = false;
        }
        if (!["percentage", "fixed"].includes(field.extra_child_formula.type)) {
          errors[`additionalFieldExtraFormulaChild${index}.type`] = "Type must be either 'percentage' or 'fixed'";
          isValid = false;
        }
        if (field.extra_child_formula.threshold === "" || isNaN(Number(field.extra_child_formula.threshold))) {
          errors[`additionalFieldExtraFormulaChild${index}.threshold`] = "Threshold is required";
          isValid = false;
        }
      });

      if (!formData.isSingleVariant && additionalFields[0].name.trim().toLowerCase() === formData.name.trim().toLowerCase()) {
        errors[`additionalFieldName${0}`] = "Name cannot be the same as the main variant name";
        isValid = false;
      }

      if (!formData.isSingleVariant && additionalFields.length > 1) {
        const names = additionalFields.map((field) => field.name.trim().toLowerCase());
        const duplicates = names.filter((item, index) => names.indexOf(item) !== index);

        if (duplicates.length > 0) {
          additionalFields.forEach((field, index) => {
            if (duplicates.includes(field.name.trim().toLowerCase())) {
              errors[`additionalFieldName${index}`] = "Variant names must be unique!";
            }
          });
          isValid = false;
        }
      }
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.pricing_type_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            name: formData.name,
            isActive: formData.isActive,
            color: formData.color,
            description: formData.description,
            isSingleVariant: formData.isSingleVariant,
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          toast.success(responseData.Message);

          if (additionalFields.length > 0) {
            const newPricingTypeId = responseData.data[0]._id;

            const variantDataToAdd = additionalFields.map((item) => ({ ...item, pricing_type_id: newPricingTypeId, urlName: hotelId }));

            const response1 = await fetch(url + clientpoints.pricing_type_variant_add, {
              method: "POST",
              headers: pageHeaders,
              credentials: "include",
              body: JSON.stringify(variantDataToAdd),
            });

            if (!response1.ok) {
              throw new Error("Failed to add variants");
            }
          }

          setFormData({
            name: "",
            isActive: false,
            color: "#000000",
            description: "",
            isSingleVariant: true,
          });
          setAdditionalFields([]);
          setIsModalOpen(false);
          onRefresh();
        } else {
          toast.error(responseData.Message);
          setIsModalOpen(false);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setFormData({
      name: "",
      isActive: false,
      color: "#000000",
      description: "",
      isSingleVariant: true,
    });
  };

  const addField = () => {
    setAdditionalFields([
      ...additionalFields,
      { name: "", description: "", formula: { ...formulaObj }, extra_adult_formula: { ...extraPriceFormulaObj }, extra_child_formula: { ...extraPriceFormulaObj } },
    ]);
  };

  const removeField = (index) => {
    const updatedFields = additionalFields.filter((_, i) => i !== index);
    setAdditionalFields(updatedFields);
  };

  const handleChangeDynamicFields = (event, index, key) => {
    if (key === "name") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`additionalFieldName${index}`]: "",
      }));
    } else if (key === "description") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`additionalFieldDescription${index}`]: "",
      }));
    }

    const value = event.target.value;
    const updatedFields = [...additionalFields];
    updatedFields[index][key] = value;
    setAdditionalFields(updatedFields);
  };

  const handleChangeInput = (e, index) => {
    const name = e.target.name;
    const [field, subField] = e.target.name.split(".");
    const value = e.target.value;

    const updatedFields = [...additionalFields];
    updatedFields[index].formula[subField] = value;
    setAdditionalFields(updatedFields);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleChangeAdultInput = (e, index) => {
    const name = e.target.name;

    const [field, subField] = e.target.name.split(".");
    const value = e.target.value;

    const updatedFields = [...additionalFields];
    updatedFields[index].extra_adult_formula[subField] = value;
    setAdditionalFields(updatedFields);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleChangeChildInput = (e, index) => {
    const name = e.target.name;

    const [field, subField] = e.target.name.split(".");
    const value = e.target.value;

    const updatedFields = [...additionalFields];
    updatedFields[index].extra_child_formula[subField] = value;
    setAdditionalFields(updatedFields);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSingleVariantToggle = () => {
    if (!formData.isSingleVariant) {
      if (additionalFields?.length > 1) {
        const confirmRemove = window.confirm("Switching to Single Variant will remove all variants after the first one. Do you want to proceed?");

        if (!confirmRemove) return;

        setAdditionalFields((prev) => {
          const updatedFields = prev.slice(0, 1);
          if (updatedFields.length > 0) {
            updatedFields[0] = { ...updatedFields[0], name: formData.name };
          }
          return updatedFields;
        });

        setErrors((prevErrors) => ({
          ...prevErrors,
          [`additionalFieldName${0}`]: "",
        }));

        setFormData((prev) => ({ ...prev, isSingleVariant: true }));
      } else {
        setFormData((prev) => ({ ...prev, isSingleVariant: true }));

        setAdditionalFields((prev) => {
          const updatedFields = [...prev];
          if (updatedFields.length > 0) {
            updatedFields[0] = { ...updatedFields[0], name: formData.name };
          }
          return updatedFields;
        });

        setErrors((prevErrors) => ({
          ...prevErrors,
          [`additionalFieldName${0}`]: "",
        }));
      }
    } else {
      setFormData((prev) => ({ ...prev, isSingleVariant: false }));
    }
  };

  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body" style={{ maxHeight: "70vh", overflowY: "auto" }}>
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-md-8">
                      <label htmlFor="name" className="col-form-label">
                        Name
                      </label>
                      <input type="text" className={`form-control ${errors.name ? "is-invalid" : ""}`} id="name" name="name" value={formData.name} onChange={handleChange} />
                      {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="name" className="col-form-label">
                        Color
                      </label>
                      <input type="color" className={`form-control `} id="color" name="color" value={formData.color} onChange={handleChange} />
                    </div>

                    <div className="col-md-8">
                      <label htmlFor="name" className="col-form-label">
                        Description
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.description ? "is-invalid" : ""}`}
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                      {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="isSingleVariant" className="col-form-label">
                        Single Variant
                      </label>
                      <div className="form-check form-switch">
                        <input type="checkbox" className="form-check-input" id="isSingleVariant" checked={formData.isSingleVariant} onChange={handleSingleVariantToggle} />
                        <label className="form-check-label" htmlFor="isSingleVariant"></label>
                      </div>
                    </div>

                    {/* <div className='d-flex align-items-center gap-3'>
                      Active
                      <label>
                        <input type='checkbox' name='isActive' checked={formData.isActive} onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))} />
                      </label>
                    </div> */}

                    <hr />

                    {additionalFields.map((field, index) => (
                      <div key={index} className="col-md-12 mb-0" id="remove_button_here">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <label htmlFor={`additionalField${index}`}>Pricing Type Variant {index + 1}</label>
                          {!formData.isSingleVariant && (
                            <button type="button" className="btn btn-danger" id="remove_button_here" onClick={() => removeField(index)}>
                              Remove
                            </button>
                          )}
                        </div>
                        <input
                          disabled={formData.isSingleVariant}
                          placeholder="Name"
                          type="text"
                          className={`form-control ${errors[`additionalFieldName${index}`] ? "is-invalid" : ""}`}
                          id={`additionalFieldName${index}`}
                          name={`additionalFieldName${index}`}
                          value={field.name}
                          onChange={(event) => handleChangeDynamicFields(event, index, "name")}
                        />
                        {errors[`additionalFieldName${index}`] && <div className="invalid-feedback">{errors[`additionalFieldName${index}`]}</div>}
                        <textarea
                          placeholder="Description"
                          rows={3}
                          className={`form-control ${errors[`additionalFieldDescription${index}`] ? "is-invalid" : ""}`}
                          id={`additionalFieldDescription${index}`}
                          name={`additionalFieldDescription${index}`}
                          value={field.description}
                          onChange={(event) => handleChangeDynamicFields(event, index, "description")}
                        />
                        {errors[`additionalFieldDescription${index}`] && <div className="invalid-feedback">{errors[`additionalFieldDescription${index}`]}</div>}
                        <FormulaBox
                          label={"Default Formula"}
                          name={`additionalFieldFormula${index}`}
                          value={field.formula}
                          onChange={(e) => handleChangeInput(e, index)}
                          errors={errors}
                          index={index}
                        />
                        <ExtrasFormulaBox
                          label={"Extra Adult Default Formula"}
                          name={`additionalFieldExtraFormulaAdult${index}`}
                          value={field.extra_adult_formula}
                          onChange={(e) => handleChangeAdultInput(e, index)}
                          errors={errors}
                          index={index}
                          type={"Adult"}
                        />
                        <ExtrasFormulaBox
                          label={"Extra Child Default Formula"}
                          name={`additionalFieldExtraFormulaChild${index}`}
                          value={field.extra_child_formula}
                          onChange={(e) => handleChangeChildInput(e, index)}
                          errors={errors}
                          index={index}
                          type={"Child"}
                        />
                      </div>
                    ))}

                    {!formData.isSingleVariant && (
                      <div className="col-md-12">
                        <button type="button" className="btn btn-success" onClick={addField}>
                          Add Variant
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const FormulaBox = ({ errors, index, label = "", name = "", value = {}, onChange = () => {} }) => {
  return (
    <>
      <div className="row">
        <label className="fw-semibold">{label}</label>

        <div className="col-sm-4">
          <NormalInput
            inputStyles={errors[`additionalFieldFormula${index}.value`] ? { marginBottom: 0, outline: "2px solid red", border: "red" } : {}}
            placeholder="Enter value..."
            type="number"
            name={name + ".value"}
            value={value.value}
            onChange={onChange}
            // min=''
          />
          {errors[`additionalFieldFormula${index}.value`] && <span style={{ color: "red", fontSize: "14px" }}>{errors[`additionalFieldFormula${index}.value`]}</span>}
        </div>
        <div className="col-sm-4">
          <NormalSelect
            inputStyles={errors[`additionalFieldFormula${index}.type`] ? { marginBottom: 0, outline: "2px solid red", border: "red" } : {}}
            name={name + ".type"}
            value={value.type}
            onChange={onChange}
            options={typeOptions}
            optionType="object"
            optionValue="value"
          />
          {errors[`additionalFieldFormula${index}.type`] && <span style={{ color: "red", fontSize: "14px" }}>{errors[`additionalFieldFormula${index}.type`]}</span>}
        </div>
        <div className="col-sm-4">
          <NormalSelect
            inputStyles={errors[`additionalFieldFormula${index}.operator`] ? { marginBottom: 0, outline: "2px solid red", border: "red" } : {}}
            name={name + ".operator"}
            value={value.operator}
            onChange={onChange}
            options={operatorOptions}
            optionType="object"
            optionValue="value"
          />
          {errors[`additionalFieldFormula${index}.operator`] && <span style={{ color: "red", fontSize: "14px" }}>{errors[`additionalFieldFormula${index}.operator`]}</span>}
        </div>
      </div>
    </>
  );
};

const ExtrasFormulaBox = ({ errors, index, type, label = "", name = "", value = {}, onChange = () => {} }) => {
  return (
    <>
      <div className="row">
        <label className="fw-semibold">{label}</label>

        <div className="col-sm-4">
          <NormalInput
            placeholder="Extra price after..."
            type="number"
            name={name + ".threshold"}
            value={value.threshold}
            onChange={onChange}
            inputStyles={errors[`additionalFieldExtraFormula${type}${index}.threshold`] ? { marginBottom: 0, outline: "2px solid red", border: "red" } : {}}

            // min=''
          />
          {errors[`additionalFieldExtraFormula${type}${index}.threshold`] && <span style={{ color: "red", fontSize: "14px" }}>{errors[`additionalFieldExtraFormula${type}${index}.threshold`]}</span>}
        </div>
        <div className="col-sm-4">
          <NormalInput
            placeholder="Enter value..."
            type="number"
            name={name + ".value"}
            value={value.value}
            onChange={onChange}
            inputStyles={errors[`additionalFieldExtraFormula${type}${index}.value`] ? { marginBottom: 0, outline: "2px solid red", border: "red" } : {}}

            // min=''
          />
          {errors[`additionalFieldExtraFormula${type}${index}.value`] && <span style={{ color: "red", fontSize: "14px" }}>{errors[`additionalFieldExtraFormula${type}${index}.value`]}</span>}
        </div>
        <div className="col-sm-4">
          <NormalSelect
            inputStyles={errors[`additionalFieldExtraFormula${type}${index}.type`] ? { marginBottom: 0, outline: "2px solid red", border: "red" } : {}}
            name={name + ".type"}
            value={value.type}
            onChange={onChange}
            options={typeOptions}
            optionType="object"
            optionValue="value"
          />
          {errors[`additionalFieldExtraFormula${type}${index}.type`] && <span style={{ color: "red", fontSize: "14px" }}>{errors[`additionalFieldExtraFormula${type}${index}.type`]}</span>}
        </div>
      </div>
    </>
  );
};

export const NormalSelect = ({ label = "", name = "", disabled = false, value = "", onChange, options = [], optionType = "string", optionLabel = "name", optionValue = "_id" }) => {
  return (
    <div className="">
      {label && <label className="form-label fw-semibold text-muted">{label}</label>}
      <select name={name} disabled={disabled} value={value} onChange={onChange} className="form-select">
        {options.map((item, i) => (
          <option key={i} value={optionType === "string" ? item : item[optionValue]}>
            {optionType === "string" ? item : item[optionLabel]}
          </option>
        ))}
      </select>
    </div>
  );
};

export const NormalInput = ({ label, type = "text", name = "", disabled = false, readOnly = false, value = "", onChange, min = "", max = "", inputStyles = {}, placeholder = "" }) => {
  return (
    <div className="">
      {label && <label className="form-label fw-semibold text-muted">{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        className={`form-control ${disabled ? "bg-light text-muted" : ""} ${readOnly ? "bg-light" : ""}`}
        style={inputStyles}
      />
    </div>
  );
};

const typeOptions = [
  {
    name: "Percentage",
    value: "percentage",
  },
  {
    name: "Fixed",
    value: "fixed",
  },
];
const operatorOptions = [
  {
    name: "Plus (+)",
    value: "+",
  },
  {
    name: "Minus (-)",
    value: "-",
  },
];

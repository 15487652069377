import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import SearchableSelectComponentHotel from "../user_select/Component.js";
import "./component.css";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 
  const [userName, setHotelName] = useState("");

  const [hotelList, setHotelList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + clientpoints.hotel_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setHotelList(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const [formData, setFormData] = useState({
    title: "",
    comment: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!userName.trim()) {
      errors.user = "User is required";
      isValid = false;
    }
    if (!formData.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }
    if (!formData.comment.trim()) {
      errors.comment = "Comment is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(url + clientpoints.ticket_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            user_id: userName,
            title: formData.title,
          }),
        });

        const responseData = await response.json();
        if (responseData.Status) {
          let ticket_id = responseData._id;
          const response_history = await fetch(url + clientpoints.ticket_history_add, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify({
              urlName: hotelId,
              user_id: userName,
              ticket_id: ticket_id,
              comment: formData.comment,
              type: "client",
            }),
          });
          setHotelName("");
          setFormData({
            title: "",
            comment: "",
          });
          const responseDataHistory = await response_history.json();
          if (responseDataHistory.Status) {
            toast.success(responseDataHistory.Message);
          } else {
            toast.error(responseDataHistory.Message);
          }
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setHotelName("");
    setFormData({
      title: "",
      comment: "",
    });
  };
  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='mb-3'>
                    <label htmlFor='title' className='col-form-label'>
                      Select User
                    </label>
                    <SearchableSelectComponentHotel
                      setHotelName={setHotelName}
                      searchValueProps={{ searchValue, setSearchValue }}
                      suggestionsProps={{ suggestions, setSuggestions }}
                      showDropdownProps={{ showDropdown, setShowDropdown }}
                      form={false}
                    />
                    {errors.user && <div className='invalid-feedback'>{errors.user}</div>}
                  </div>

                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <label htmlFor='title' className='col-form-label'>
                        Title
                      </label>
                      <input type='text' className={`form-control ${errors.title ? "is-invalid" : ""}`} id='title' name='title' value={formData.title} onChange={handleChange} />
                      {errors.title && <div className='invalid-feedback'>{errors.title}</div>}
                    </div>
                    <div className='col-md-12 mb-3'>
                      <label htmlFor='comment' className='col-form-label'>
                        Comment:
                      </label>
                      <textarea type='text' className={`form-control ${errors.comment ? "is-invalid" : ""}`} id='comment' value={formData.comment} name='comment' onChange={handleChange} />
                      {errors.comment && <div className='invalid-feedback'>{errors.comment}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary'>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

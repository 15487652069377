import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext";
import { Button, Table, Form } from "react-bootstrap";
import { FaEdit, FaTrashAlt, FaSyncAlt, FaSort, FaSearch, FaDna, FaFilter, FaSortUp, FaSortDown, FaSortAmountUpAlt, FaSortAmountDownAlt } from "react-icons/fa";

import { toast } from "react-toastify";
import AddModalComponent from "../add_modal/Component.js";
import EditModalComponent from "../edit_modal/Component.js";
import "./component.css";
import "../../../../../nav/components/location/component.css";

import ConfirmModal from "../../../ui/modals/ConfirmDeleteModal.js";
import { useParams } from "react-router-dom";
import useHotelId from "../../../../../../lib/useHotelId.js";

const Component = ({ name = "All" }) => {
  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isViewModal, setIsViewModal] = useState(false);

  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteItemType, setDeleteItemType] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPages: 0,
  });

  const [sorting, setSorting] = useState({ column: null, direction: "desc" });
  const adjustedStartIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;

  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };

      let obj = {
        filters: {
          // ...(hotelId && { hotel_id: hotelId }),
        },
      };

      const requestBody = {
        ...obj,
        urlName: hotelId,
        search: searchTerm,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.feedback_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      setIsError(true);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting.column, sorting.direction]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = {};
      setSearchTerm("");

      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
        filters: {},
        // filters: { ...(hotelId && { hotel_id: hotelId }) },
      };
      const response = await fetch(url + clientpoints.feedback_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      // setPagination(prevPagination => ({ ...prevPagination, pageIndex: 1, totalPages: Math.ceil(responseData.totalCount / pagination.pageSize) }));
      setPagination({ pageIndex: 1, pageSize: 10, totalPages: 0 });
    } catch (error) {
      console.error("Error searching data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction: prevSorting.column === column && prevSorting.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
  };

  const renderPageRange = () => {
    // const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    // const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    // let filterText = "";
    // if (showFilterMsg && filter != 0) {
    //   filterText = ` total ${total} (filtered from ${filter})`;
    // } else {
    //   filterText = ` ${total}`;
    // }
    // return `Showing ${startIndex} to ${endIndex} of ${filterText} entries`;

    let startIndex;
    startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    if (total === 0) {startIndex = 0;}
    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    if (startIndex > endIndex) {setPagination((prev) => ({...prev,pageIndex: prev.pageIndex - 1,})); return;  }
    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` of total ${total} (filtered ${filter})`;
    } else {
      filterText = ` of ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex}${filterText} entries`;
  };

  const handleDeleteClick = (deleteItemId, type) => {
    setDeleteItemId(deleteItemId);
    setDeleteItemType(type);
    setIsDeleteModal(true);
  };

  const handleDelete = async (deleteItemId, type) => {
    
    // let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    // if (deleteConfirm == type) {
      try {
        const response = await fetch(url + clientpoints.feedback_delete, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({ filters: { _id: deleteItemId } }),
        });
        const responseData = await response.json();

        if (!response.ok) {
          throw new Error("Failed to delete item");
        }

        if (responseData.Status) {
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }

        setData((prevData) => prevData.filter((item) => item._id !== deleteItemId));
        fetchData();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    // } else {
    //   toast.error("Not Matched");
    // }
  };

  const handleEditClick = (item) => {
    setSelectedItem(item._id);
    setIsModalOpen(true);
  };

  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const handleOpenModalAdd = () => {
    setIsModalOpenAdd(true);
  };

  return (
    <>
      <div className="inner-padding">
        <div className="featured-flx">
          <div className="featured-flx1">
            <p className="main_heading1">{name}</p>
          </div>

          <div className="featured-flx1">
            {whoAmI === "team" ? (
              <>
                {typeTeamRoleObject?.user?.add && (
                  <button className="circle-btn-add icon" type="button" onClick={handleOpenModalAdd}>
                    {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="ri-links-line"></i>}
                  </button>
                )}
              </>
            ) : (
              <>
                <button className="circle-btn-add icon" type="button" onClick={handleOpenModalAdd}>
                  {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="ri-links-line"></i>}
                </button>
              </>
            )}
          </div>
        </div>

        <div className="featured-flx1">
          <div className="row" style={{ width: "100%" }}>
            <div className="suport">
              <div className="table table-responsive mt-2 tablebord">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div className="d-flex">
                    <button onClick={handleRefresh} className="btn btn-primary" style={{ background: "#0d6efd", color: "white" }}>
                      {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="ri-refresh-line"></i>}
                      &#160; Refresh
                    </button>
                    &#160;&nbsp;&nbsp;
                    {/* <button className="btn btn-primary" onClick={handleHardRefresh} style={{ background: "#0d6efd", color: "white" }}>
                      <i className="ri-gradienter-line"></i> Reset
                    </button> */}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyUp={handleSearchSubmit}
                        className="search-input form-control search-inp"
                      />
                    </div>
                  </div>
                </div>
                <div className="support_table">
                  <div className="scroll_table-space">
                    <Table className="hover table-responsive">
                      <thead>
                        <tr>
                          <th>S. No </th>
                          <th>Property</th>
                          <th onClick={() => handleSort("name")}>
                            Name <FaFilter />
                            &nbsp; {sorting.column === "name" && (sorting.direction === "asc" ? <FaSortAmountUpAlt /> : <FaSortAmountDownAlt />)}
                          </th>
                          <th>Room Quality</th>
                          <th>Overall Experience</th>
                          <th>Staff Service</th>
                          <th>Facilities Amenities</th>
                          <th>Location Convenience</th>
                          <th>Value For Money</th>
                          <th>Phone </th>
                          <th>DOB </th>
                          <th>Anniversary </th>
                          <th>Feedback </th>

                          {/* <th>Reason </th> */}
                          <th onClick={() => handleSort("createdAt")}>
                            Date <FaFilter />
                            &nbsp; {sorting.column === "createdAt" && (sorting.direction === "asc" ? <FaSortAmountUpAlt /> : <FaSortAmountDownAlt />)}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <div className="d-flex justify-content-center align-items-center myload">
                            <div className="spinner-border text-primary " role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : data.length > 0 ? (
                          data.map((item, index) => (
                            <tr key={index}>
                              <td>{adjustedStartIndex + index}</td>

                              <td>{item.property_id?.title}</td>
                              <td>{item.name}</td>
                              <td>{item.roomQuality}</td>
                              <td>{item.overallExperience}</td>
                              <td>{item.staffService}</td>
                              <td>{item.facilitiesAmenities}</td>
                              <td>{item.locationConvenience}</td>
                              <td>{item.valueForMoney}</td>
                              <td>{item.mobileNumber}</td>
                              <td>{item.dob}</td>
                              <td>{item.anniversary}</td>
                              <td>{item.feedback}</td>

                              <td>{new Date(item.createdAt).toLocaleString()}</td>
                              <td>
                                <div className="mybots">
                                  {whoAmI === "team" ? (
                                    <>
                                      {typeTeamRoleObject?.user?.view && (
                                        <button
                                          variant="primary edit-btn"
                                          className="edit-btn1"
                                          onClick={() => {
                                            setIsViewModal(true);
                                            setSelectedItem(item._id);
                                          }}
                                        >
                                          <i className="ri-eye-line"></i>
                                        </button>
                                      )}
                                      {typeTeamRoleObject?.user?.edit && (
                                        <button variant="primary edit-btn" className="edit-btn1" onClick={() => handleEditClick(item)}>
                                          <i className="ri-pencil-line"></i>
                                        </button>
                                      )}
                                      {typeTeamRoleObject?.user?.delete && (
                                        <button variant="danger edit-btn" className="delete-btn1" onClick={() => handleDeleteClick(item._id, item.name)}>
                                          <i className="ri-delete-bin-line"></i>
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {/* <button
                                        variant="primary edit-btn"
                                        className="edit-btn1"
                                        onClick={() => {
                                          setIsViewModal(true);
                                          setSelectedItem(item._id);
                                        }}
                                      >
                                        <i className="ri-eye-line"></i>
                                      </button> 
                                      <button variant="primary edit-btn" className="edit-btn1" onClick={() => handleEditClick(item)}>
                                        <i className="ri-pencil-line"></i>
                                      </button>
                                      */}
                                      <button variant="danger edit-btn" className="delete-btn1" onClick={() => handleDeleteClick(item._id, item.property_id?.title)}>
                                        <i className="ri-delete-bin-line"></i>
                                      </button>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="middledata">
                            <td colSpan={15}>
                              <div className="nodatafound1">No data found</div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>

              <div className="row pegination_section_bottom">
                <div className="pagination-container page-flx1">
                  <div className="row">
                    <div className="col-xs-12 col-md-4">
                      <Form.Label>Rows per page:</Form.Label>
                      <select as="select" onChange={handlePageSizeChange} value={pagination.pageSize} className="dropon">
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                      </select>
                    </div>

                    <div className="col-md-4"></div>

                    <div className="col-xs-12 col-md-4 mt-3" style={{ textAlign: "center" }}>
                      <div className=" flex-pagination">
                        <div>{renderPageRange()}</div>
                        <div className="mypage1">
                          {pagination.pageIndex !== 1 && data.length !== 0 && (
                            <button
                              disabled={pagination.pageIndex === 1}
                              onClick={() =>
                                setPagination((prevPagination) => ({
                                  ...prevPagination,
                                  pageIndex: prevPagination.pageIndex - 1,
                                }))
                              }
                              class="button left-clickbtn"
                              id="button1"
                            >
                              <i className="ri-arrow-left-s-line"></i>
                            </button>
                          )}

                          <div class="button active" id="button1">
                            {pagination.pageIndex}
                          </div>
                          {pagination.pageIndex !== pagination.totalPages && data.length !== 0 && (
                            <button
                              disabled={pagination.pageIndex === pagination.totalPages}
                              onClick={() =>
                                setPagination((prevPagination) => ({
                                  ...prevPagination,
                                  pageIndex: prevPagination.pageIndex + 1,
                                }))
                              }
                              class="button left-clickbtn"
                              id="button4"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isDeleteModal && (
          <ConfirmModal
            isModalOpen={isDeleteModal}
            setIsModalOpen={setIsDeleteModal}
            modalName="Confirm Deletion"
            deleteItemId={deleteItemId}
            setDeleteItemId={setDeleteItemId}
            deleteItemType={deleteItemType}
            setDeleteItemType={setDeleteItemType}
            handleDelete={handleDelete}
          />
        )}

        {isModalOpenAdd && <AddModalComponent isModalOpen={isModalOpenAdd} setIsModalOpen={setIsModalOpenAdd} modalName={"Generate Feedback URL"} onRefresh={handleRefresh} />}

        {isModalOpen && <EditModalComponent isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={selectedItem} modalName={"Guest"} onRefresh={handleRefresh} view="edit" />}

        {isViewModal && <EditModalComponent isModalOpen={isViewModal} setIsModalOpen={setIsViewModal} data={selectedItem} modalName={"Guest"} onRefresh={handleRefresh} view="view" />}
      </div>
    </>
  );
};

export default Component;

import Quotations from "../../components/quotation/all_quotation/table/Component";
import { toast, ToastContainer } from "react-toastify";

export default function Component() {
  return (
    <>
      <ToastContainer />
      <Quotations name="Quotations" />
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, pageHeaders, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./component.css";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isChatOpen, setIsChatOpen, clientItem, chatItem, hotelItem, createdAt, modalName }) {
  const navigate = useNavigate();
  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [chatList, setChatList] = useState([]);

  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fetchData, setFetchData] = useState("");
  const [status, setStatus] = useState(false);

  const handleRefresh = () => {
    setIsLoadingRefresh(true);
    setTimeout(() => {
      fetchChatDataTickect();
      fetchChatData();
      setIsLoadingRefresh(false);
    }, 1000);
  };

  useEffect(() => {
    fetchChatDataTickect();
    fetchChatData();
  }, [clientItem, chatItem]);

  let clientId = clientItem; // Client Self Id
  let ticketId = chatItem; // Client Ticket Id
  // let hotelId = hotelItem; // Client Ticket Id

  const fetchChatDataTickect = async () => {
    try {
      const response = await fetch(url + clientpoints.client_ticket_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: ticketId } }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      if (responseData.Status && responseData.Data.length > 0) {
        setFetchData(responseData.Data[0]);
        setStatus(responseData.Data[0].status);
      }
      // setTotal(responseData.totalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchChatData = async () => {
    try {
      const response = await fetch(url + clientpoints.client_ticket_history_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { client_ticket_id: ticketId } }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setChatList(data.Data);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const [formData, setFormData] = useState({ comment: "" });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    if (!formData.comment.trim()) {
      errors.comment = "Comment is required";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all details!");
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.client_ticket_history_add, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          client_id: clientId,
          urlName: hotelId,
          client_ticket_id: ticketId,
          comment: formData.comment,
          type: "client",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to submit data. Please try again.");
      }

      const responseData = await response.json();
      if (responseData.Status) {
        toast.success(responseData.Message);
      } else {
        toast.error(responseData.Message);
      }
      setFormData({ comment: "" });
      fetchChatData();
    } catch (error) {
      toast.error(error.message || "Failed to submit data. Please try again.");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsChatOpen(false);
    setFormData({ comment: "" });
  };

  const handleDeleteChat = async (chatId) => {
    let deleteConfirm = confirm("Do you want to delete this record.");
    if (deleteConfirm) {
      try {
        const response = await fetch(url + clientpoints.client_ticket_history_delete, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({ urlName: hotelId, filters: { _id: chatId } }),
        });
        if (!response.ok) {
          throw new Error("Failed to delete chat");
        }
        setChatList((prevData) => prevData.filter((item) => item._id !== chatId));
        // fetchChatData();
      } catch (error) {
        console.error("Error deleting chat:", error.message);
      }
    }
  };

  const renderChatBubbles = () => {
    return chatList.map((chat) => {
      const bubblePosition = chat.type === "client" ? "end" : "start";
      return (
        <div key={chat.id}>
          <div className={`d-flex flex-row justify-content-${bubblePosition} mb-1`}>
            <div className={`p-3  ${bubblePosition === "start" ? "ms-3 first-rply message-text" : "me-3 reply_msg"}`}>
              <p className='small mb-0'>{chat.comment}</p>
            </div>
            {/* <div className='iconscenter edit' onClick={() => handleDeleteChat(chat._id)}><i className="ri-delete-bin-line delet_icn1"></i></div> */}
            {/* {chat.type === 'client' ? (
              <div className='iconscenter edit' onClick={() => handleDeleteChat(chat._id)}>
                <i className="ri-delete-bin-line delet_icn1"></i>
              </div>
            ) : null} */}
          </div>
        </div>
      );
    });
  };

  const handleToggle = async () => {
    setStatus((prevStatus) => !prevStatus);
    let temp_status;
    if (status == true) {
      temp_status = false;
    } else {
      temp_status = true;
    }
    try {
      const response = await fetch(url + clientpoints.client_ticket_status, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: ticketId }, data: { status: temp_status } }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error("Failed to delete item");
      }
      if (data.Status) {
        toast.success(data.Message);
      } else {
        toast.error(data.Message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <>
      {isChatOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <p>
                    Title: {fetchData && fetchData.title ? `(${fetchData.title})` : null}, Hotel:{" "}
                    {fetchData && fetchData.hotel_id && fetchData.hotel_id.hotel_name ? `(${fetchData.hotel_id.hotel_name})` : null}
                  </p>
                  <div className='scroll_chat'>{renderChatBubbles()}</div>
                  <div className='form-outline'>
                    {status ? (
                      <>
                        <textarea type='text' className={`form-control ${errors.comment ? "is-invalid" : ""}`} id='comment' value={formData.comment} name='comment' onChange={handleChange} />
                        {errors.comment && <div className='invalid-feedback'>{errors.comment}</div>}

                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='create-type'>
                            <div className='create-type1'>
                              <span className='float-start'>CreatedAt : {new Date(createdAt).toLocaleDateString()}</span>
                              <p>Ticket Status : {status ? "Open" : "Closed"}</p>
                            </div>
                          </div>
                          <div className='d-flex gap-2'>
                            <button type='button' className='btn btn-secondary ms-2' onClick={handleRefresh} disabled={isLoading}>
                              {isLoadingRefresh ? <span className='spinner-border spinner-border-sm me-1' role='status' aria-hidden='true'></span> : null} Refresh
                            </button>
                            <button type='submit' className='btn btn-primary'>
                              {isLoading ? <span className='spinner-border spinner-border-sm me-1' role='status' aria-hidden='true'></span> : null} Submit
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>Ticket : {status ? "Open" : "Closed"}</p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

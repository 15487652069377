import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";

import { toast } from "react-toastify";
import "./component.css";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;
  
  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);
  
  const initialFormData = {
    name: "",
    domain: "",
    url: "",
    description: "",
    theme: "",
  };
  
  const [isSubmittingError, setIsSubmittingError] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleChangeObjectInput = (e, type) => {
    setFormData((prev) => ({
      ...prev,
      [type]: { ...prev[type], [e.target.name]: e.target.value },
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.domain.trim()) {
      errors.domain = "Domain is required";
      isValid = false;
    } 

    if (!formData.url.trim()) {
      errors.url = "URL is required";
      isValid = false;
    } 

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.website_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            ...formData,
          }),
        });

        setFormData(initialFormData);
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  const handleURLBlur = async () => {
    const property_url = formData.url.trim();
    if (property_url) {
      const exists = await checkExistsInDataBase("url", property_url, "URL", "website_count");
      if (exists) {
        setErrors({ ...errors, url: "URL Name already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, url: "" });
        setIsSubmittingError(false);
      }
    }
  };

  const handleDomainBlur = async () => {
    const property_domain = formData.domain.trim();
    if (property_domain) {
      const exists = await checkExistsInDataBase("domain", property_domain, "Domain", "website_count");
      if (exists) {
        setErrors({ ...errors, domain: "Domain Name already exists" });
        setIsSubmittingError(true);
      } else {
        setErrors({ ...errors, domain: "" });
        setIsSubmittingError(false);
      }
    }
  };

  const checkExistsInDataBase = async (key, value, msg, table) => {
    try {
      const response = await fetch(url + clientpoints[table], {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId, filters: { [key]: value, delete_status: { $in: [true, false] } } }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.Count >= 1) {
        toast.warning(`${msg} exists!`);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast.error("Error");
      return true;
    }
  };

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type="button" className="btn btn-danger" onClick={handleCloseModal} style={{ marginLeft: "8px" }}><i class="ri-close-line"></i></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='row'>

                    <div className='col-md-6'>
                      <label htmlFor='name' className='col-form-label'>Name</label>
                      <input type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                      {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor='domain' className='col-form-label'>Domain</label>
                      <input type='text' className={`form-control ${errors.domain ? "is-invalid" : ""}`} id='domain' name='domain' value={formData.domain} onChange={handleChange} onBlur={handleDomainBlur} />
                      {errors.domain && <div className='invalid-feedback'>{errors.domain}</div>}
                    </div>
                    
                    <div className='col-md-6'>
                      <label htmlFor='url' className='col-form-label'>URL</label>
                      <input type='text' className={`form-control ${errors.url ? "is-invalid" : ""}`} id='url' name='url' value={formData.url} onChange={handleChange} onBlur={handleURLBlur} />
                      {errors.url && <div className='invalid-feedback'>{errors.url}</div>}
                    </div>
                   
                    <div className='col-md-6'>
                      <label htmlFor='theme' className='col-form-label'>Theme</label>
                      <input type='text' className={`form-control`} id='theme' name='theme' value={formData.theme} onChange={handleChange} />
                    </div>
                    
                    <div className='col-md-12'>
                      <label htmlFor='description' className='col-form-label'>Description</label>
                      <textarea className={`form-control`} id='description' name='description' value={formData.description} onChange={handleChange} />
                    </div>

                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Rating from "@mui/material/Rating";
import "./component.css";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, view = "view" }) {
  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const [mode, setMode] = useState(view);

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [formData, setFormData] = useState({
    rating: 0,
    review: "",
    feedback: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let bookingId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // if (!formData.rating.trim()) {
    //   errors.rating = "Rating is required";
    //   isValid = false;
    // }

    if (!formData.review.trim()) {
      errors.review = "Review is required";
      isValid = false;
    }

    if (!formData.feedback.trim()) {
      errors.feedback = "Feedback is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && bookingId) {
      setLoading(true);
      fetchData(bookingId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, bookingId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.feedback_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { booking_id: bookingId } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        console.log("response data", response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          feedback: response_data?.feedback,
          rating: response_data?.rating || 0,
          review: response_data?.review || "",
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.feedback_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: current_id },
            data: {
              feedback: formData.feedback,
              review: formData.review,
              rating: formData.rating,
            },
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="rating" className="col-form-label">
                          Rating
                        </label>
                        <div>
                          <Rating
                            readOnly={mode === "view"}
                            required
                            name="rating"
                            value={formData.rating}
                            onChange={(event, newValue) => {
                              setFormData((prev) => ({ ...prev, rating: newValue }));
                            }}
                          />
                          {errors.rating && <div className="invalid-feedback">{errors.rating}</div>}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="review" className="col-form-label">
                          Review
                        </label>
                        <textarea
                          disabled={mode === "view"}
                          className={`form-control ${errors.review ? "is-invalid" : ""}`}
                          id="review"
                          name="review"
                          value={formData.review}
                          onChange={handleChange}
                        />
                        {errors.review && <div className="invalid-feedback">{errors.review}</div>}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="feedback" className="col-form-label">
                          Feedback
                        </label>
                        <textarea
                          disabled={mode === "view"}
                          className={`form-control ${errors.feedback ? "is-invalid" : ""}`}
                          id="feedback"
                          name="feedback"
                          value={formData.feedback}
                          onChange={handleChange}
                        />
                        {errors.feedback && <div className="invalid-feedback">{errors.feedback}</div>}
                      </div>
                    </div>
                  </div>
                )}
                <div className="modal-footer d-flex justify-content-between align-items-center">
                  <p className="float-start">CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className="d-flex gap-2">
                    {/* <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                      Close
                    </button> */}
                    {/* <button type="submit" className="btn btn-primary">Update</button> */}
                    {mode !== "view" && (
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        {isSubmitting ? "Loading..." : "Update"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./component.css";

import Select from "react-select";
import { Country, State, City } from "country-state-city";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [selectValues, setSelectValues] = useState({
    country: { label: "", value: "" },
    state: {
      label: "",
      value: "",
    },
    city: {
      label: "",
      value: "",
    },
  });

  const initialFormData = {
    country: { name: "", isoCode: "" },
    state: { name: "", isoCode: "" },
    city: "",
    area_name: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  // useEffect(() => {
  const allCountries = Country.getAllCountries();
  const countries = allCountries.map((con) => ({ label: con.name, value: con.isoCode }));
  // setCountries(countryList);
  // }, []);

  useEffect(() => {
    const country = countries.find((item) => item.value === formData.country.isoCode) || { label: "", value: "" };
    setSelectValues((prev) => ({
      ...prev,
      country,
    }));

    const statesOfCountry = State.getStatesOfCountry(formData.country.isoCode);
    const statesList = statesOfCountry.map((state) => ({ label: state.name, value: state.isoCode }));

    setStates(statesList);
  }, [formData.country.isoCode]);

  useEffect(() => {
    const state = states.find((item) => item.value === formData.state.isoCode) || { label: "", value: "" };
    setSelectValues((prev) => ({
      ...prev,
      state,
    }));

    const citiesOfState = City.getCitiesOfState(formData.country.isoCode, formData.state.isoCode);

    setCities(citiesOfState.map((city) => ({ label: city.name, value: city.name })));
  }, [formData.country.isoCode, formData.state.isoCode, states]);

  useEffect(() => {
    const city = cities.find((item) => item.value === formData.city) || { label: "", value: "" };

    setSelectValues((prev) => ({
      ...prev,
      city,
    }));
  }, [formData.city, formData.state.isoCode, cities]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.country.isoCode.trim()) {
      errors.country = "Country is required";
      isValid = false;
    }

    if (!formData.state.isoCode.trim()) {
      errors.state = "State is required";
      isValid = false;
    }

    if (!formData.city.trim()) {
      errors.city = "City is required";
      isValid = false;
    }

    if (!formData.area_name.trim()) {
      errors.area_name = "Area is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.area_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            country: { ...formData.country },
            state: { ...formData.state },
            city: formData.city,
            area_name: formData.area_name,
          }),
        });
        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);

          setFormData(initialFormData);
          setIsModalOpen(false);
        } else {
          toast.error(responseData.Message);
          setIsModalOpen(false);
        }
        onRefresh();
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setFormData(initialFormData);
  };
  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label htmlFor='country' className='col-form-label'>
                        Country
                      </label>
                      <Select
                        required
                        options={countries}
                        // options={[
                        //   {
                        //     label: "India",
                        //     value: "IN",
                        //   },
                        // ]}
                        value={selectValues.country}
                        onChange={(val) =>
                          setFormData((prev) => ({
                            ...prev,
                            country: { name: val.label, isoCode: val.value },
                            state: { name: "", isoCode: "" },
                            city: "",
                          }))
                        }
                        placeholder='Select Country...'
                        className='flex-1 w-full'
                        styles={customStyles}
                        menuPortalTarget={document.body}
                      />
                      {errors.country && <div className='invalid-feedback'>{errors.country}</div>}
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='state' className='col-form-label'>
                        State
                      </label>
                      <Select
                        required
                        isDisabled={!formData.country.isoCode}
                        options={states}
                        value={selectValues.state}
                        onChange={(val) =>
                          setFormData((prev) => ({
                            ...prev,
                            state: { name: val.label, isoCode: val.value },
                            city: "",
                          }))
                        }
                        placeholder='Select State...'
                        className='flex-1 w-full'
                        styles={customStyles}
                        menuPortalTarget={document.body}
                      />
                      {errors.state && <div className='invalid-feedback'>{errors.state}</div>}
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='city' className='col-form-label'>
                        City
                      </label>
                      <Select
                        required
                        isDisabled={!formData.country.isoCode || !formData.state.isoCode}
                        options={cities}
                        value={selectValues.city}
                        onChange={(val) =>
                          setFormData((prev) => ({
                            ...prev,
                            city: val.value,
                          }))
                        }
                        placeholder='Select City...'
                        className='flex-1 w-full'
                        styles={customStyles}
                        menuPortalTarget={document.body}
                      />
                      {errors.city && <div className='invalid-feedback'>{errors.city}</div>}
                    </div>

                    <div className='col-md-12'>
                      <label htmlFor='area_name' className='col-form-label'>
                        Area
                      </label>
                      <input type='text' className={`form-control ${errors.area_name ? "is-invalid" : ""}`} id='area_name' name='area_name' value={formData.area_name} onChange={handleChange} />
                      {errors.area_name && <div className='invalid-feedback'>{errors.area_name}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};

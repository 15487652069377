import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

import "./component.css";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, view = "view" }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const [formData, setFormData] = useState({
    name: "",
    domain: "",
    url: "",
    description: "",
    theme: "",
  });

  const [mode, setMode] = useState(view);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let property_typeId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.domain.trim()) {
      errors.domain = "Domain is required";
      isValid = false;
    } 

    if (!formData.url.trim()) {
      errors.url = "URL is required";
      isValid = false;
    } 

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && property_typeId) {
      setLoading(true);
      fetchData(property_typeId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, property_typeId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.website_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(client_prefix);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          name: response_data.name,
          domain: response_data.domain,
          url: response_data.url,
          description: response_data.description,
          theme: response_data.theme,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.website_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: current_id },
            data: {
              name: formData.name,
              domain: formData.domain,
              url: formData.url,
              description: formData.description,
              theme: formData.theme
            },
          }),
        });

        if (!response.ok) {
          navigate(client_prefix);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {mode === "view" ? `${modalName} (View)` : `${modalName} (Edit)`}{" "}
                </h5>
                {mode === "view" && (
                  <button className="ms-auto btn btn-primary " onClick={() => setMode("edit")}>
                    <i class="ri-edit-box-line"></i>
                  </button>
                )}
                <button type="button" className="btn btn-danger" onClick={handleCloseModal} style={{ marginLeft: "8px" }}>
                  <i class="ri-close-line"></i>
                </button>
              </div>

              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <div className="row">
                      <div className='col-md-6'>
                        <label htmlFor='name' className='col-form-label'>Name</label>
                        <input disabled={mode === "view"} type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                        {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='domain' className='col-form-label'>Domain</label>
                        <input disabled type='text' className={`form-control ${errors.domain ? "is-invalid" : ""}`} id='domain' name='domain' value={formData.domain} onChange={handleChange} />
                        {errors.domain && <div className='invalid-feedback'>{errors.domain}</div>}
                      </div>
                      
                      <div className='col-md-6'>
                        <label htmlFor='url' className='col-form-label'>URL</label>
                        {/* disabled={mode === "view"} */}
                        <input disabled type='text' className={`form-control ${errors.url ? "is-invalid" : ""}`} id='url' name='url' value={formData.url} onChange={handleChange} />
                        {errors.url && <div className='invalid-feedback'>{errors.url}</div>}
                      </div>
                    
                      <div className='col-md-6'>
                        <label htmlFor='theme' className='col-form-label'>Theme</label>
                        <input disabled={mode === "view"} type='text' className={`form-control`} id='theme' name='theme' value={formData.theme} onChange={handleChange} />
                      </div>
                      
                      <div className='col-md-12'>
                        <label htmlFor='description' className='col-form-label'>Description</label>
                        <textarea disabled={mode === "view"} className={`form-control`} id='description' name='description' value={formData.description} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="modal-footer d-flex justify-content-between align-items-center">
                  {formData.description ? (<p className="float-start">CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>):(<><p className="float-start"></p></>)}
                  <div className="d-flex gap-2">
                    {mode !== "view" && (
                      <>
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                          {isSubmitting ? "Loading..." : "Update"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { format } from "date-fns";

import "./component.css";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ view = "view", isModalOpen, setIsModalOpen, data, onRefresh, modalName }) {
  // const { hotelId } = useParams();
  const hotelId = useHotelId();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  const [mode, setMode] = useState(view);

  const [isPriceCalculating, setIsPriceCalculating] = useState(false);

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [maxValuesAllowed, setMaxValuesAllowed] = useState({
    adults: null,
    kids: null,
  });

  const [formData, setFormData] = useState(initialFormData);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [properties, setProperties] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [pricingTypes, setPricingTypes] = useState([]);
  const [pricingTypeVariants, setPricingTypeVariants] = useState([]);

  const [availableRoomsCount, setAvailableRoomsCount] = useState(0);
  const [totalRoomsCount, setTotalRoomsCount] = useState(0);

  const [selectedValues, setSelectedValues] = useState({
    property: "",
    room: "",
    pricingType: "",
    pricingTypeVariant: "",
    showRooms: [],
  });

  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let quotationId = data;

  const [tempSelectedData, setTempSelectedData] = useState({
    room_id: "",
    room_name_id: "",
    pricing_type_id: "",
    pricing_type_variant_id: "",
    guests: 1,
    children: 0,
    rooms_to_book: 1,
    price_per_night: 0,
    total_base_price: 0,
  });

  const [rows, setRows] = useState([]);

  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    const computeSummaryData = () => {
      const groupedData = rows.reduce((acc, row) => {
        const key = `${row.room_id}-${row.pricing_type_id}-${row.pricing_type_variant_id}`;

        if (!acc[key]) {
          acc[key] = {
            roomDetails: row.room,
            qty: 0,
            totalAdults: 0,
            totalChildren: 0,
            totalPricePerNight: 0,
            totalPriceForAllNights: 0,
          };
        }

        acc[key].qty += 1; //  rooms_to_book in each row is set to 1
        acc[key].totalAdults += Number(row.guests || 0);
        acc[key].totalChildren += Number(row.children || 0);
        acc[key].totalPricePerNight += Number(row.price_per_night || 0) - Number(row.discount || 0);
        acc[key].totalPriceForAllNights += (Number(row.price_per_night || 0) - Number(row.discount || 0)) * Number(formData.total_nights || 1);

        return acc;
      }, {});

      const groupedArray = Object.values(groupedData);

      setSummaryData(groupedArray);

      const total = groupedArray.reduce((sum, item) => sum + item.totalPriceForAllNights, 0);

      setFormData((prev) => ({ ...prev, sub_total: total }));
    };

    computeSummaryData();
  }, [rows, formData.total_nights]);

  const calculateGrandTotal = () => formData.sub_total + Number(formData.tax_amount || 0);

  useEffect(() => {
    fetchProperties();
    fetchPricingTypes();
  }, []);

  const fetchPricingTypes = async () => {
    try {
      const response = await fetch(url + clientpoints.pricing_type_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ urlName: hotelId, filters: {} }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setPricingTypes(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "tax_slab") {
      const taxPercentage = parseFloat(value?.replace("%", "")) || 0;

      const taxAmount = (formData.sub_total * taxPercentage) / 100;

      setFormData((prev) => ({
        ...prev,
        [name]: value,
        tax_amount: taxAmount,
      }));
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "check_in") {
      setFormData((prevData) => ({
        ...prevData,
        check_out: "",
      }));
    }
  };

  const handleFieldsToTrack = (name) => {
    const fieldsToTrack = ["check_in", "check_out", "property_id"];

    if (fieldsToTrack.includes(name)) {
      setIsInitialRender(false);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({
          urlName: hotelId,
          // filters: { area: area._id },
          filters: {},
        }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client data");
      }
      const data = await response.json();
      setProperties(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const property = properties.find((item) => item._id === formData.property_id) || "";

    const fetchRooms = async () => {
      try {
        const response = await fetch(url + clientpoints.room_view, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { property_id: property._id },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setRooms(data.Data);
        setSelectedValues((prev) => ({
          ...prev,
          property: property ? { label: property.title, value: property._id } : "",
          showRooms: data.Data,
        }));
      } catch (error) {
        toast.error(error);
      }
    };

    if (formData.property_id) {
      fetchRooms();
    }
  }, [formData.property_id, properties]);

  useEffect(() => {
    if (tempSelectedData.room_id) {
      const room = rooms.find((item) => item._id === tempSelectedData.room_id);

      setMaxValuesAllowed({
        adults: room?.num_of_adults || 1,
        kids: room?.num_of_kids || 0,
      });

      setSelectedValues((prev) => ({
        ...prev,
        room: room ? { label: `${room.name} (${room.room_type.name})`, value: room._id } : "",
      }));
    }
  }, [tempSelectedData.room_id, rooms]);

  useEffect(() => {
    const pricingType = pricingTypes.find((item) => item._id === tempSelectedData.pricing_type_id) || "";

    const fetchPricingTypeVariants = async () => {
      try {
        const response = await fetch(url + clientpoints.pricing_type_variant_view, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { pricing_type_id: pricingType._id },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setPricingTypeVariants(data.Data);
        setSelectedValues((prev) => ({
          ...prev,
          pricingType: pricingType ? { label: pricingType.name, value: pricingType._id } : "",
        }));
      } catch (error) {
        toast.error(error);
      }
    };

    if (tempSelectedData.pricing_type_id) {
      fetchPricingTypeVariants();
    }
  }, [tempSelectedData.pricing_type_id, pricingTypes]);

  useEffect(() => {
    const pricingTypeVariant = pricingTypeVariants.find((item) => item._id === tempSelectedData.pricing_type_variant_id);
    setSelectedValues((prev) => ({
      ...prev,
      pricingTypeVariant: pricingTypeVariant ? { label: pricingTypeVariant.name, value: pricingTypeVariant._id } : "",
    }));
  }, [tempSelectedData.pricing_type_variant_id, pricingTypeVariants]);

  useEffect(() => {
    const property = properties.find((item) => item._id === formData.property_id) || "";
    const room = rooms.find((item) => item._id === tempSelectedData.room_id) || "";
    const check_in = formData.check_in;
    const check_out = formData.check_out;

    const fetchAvailableRoomNames = async () => {
      try {
        const response = await fetch(url + clientpoints.room_name_available, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { property_id: property._id, room_id: room._id, check_in: check_in, check_out: check_out },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();

        const initialAvailableRoomsCount = data.Data.length;

        const bookedRoomsCount = rows.filter((row) => row.room_id === room._id).length;

        const availableRoomsCount = initialAvailableRoomsCount - bookedRoomsCount;

        setTotalRoomsCount(initialAvailableRoomsCount);
        setAvailableRoomsCount(availableRoomsCount);

        setFormData((prev) => ({
          ...prev,
          total_nights: formData.check_in && formData.check_out ? Math.ceil((new Date(formData.check_out) - new Date(formData.check_in)) / (1000 * 60 * 60 * 24)) : "",
        }));
      } catch (error) {
        toast.error(error);
      }
    };

    if (property._id && tempSelectedData.room_id && formData.check_in && formData.check_out) {
      fetchAvailableRoomNames();
    }
  }, [formData.check_in, formData.check_out, formData.property_id, tempSelectedData.room_id, rooms, properties]);

  useEffect(() => {
    const property = properties.find((item) => item._id == formData.property_id) || "";
    const room = rooms.find((item) => item._id == tempSelectedData.room_id) || "";
    const pricingTypeVariant = pricingTypeVariants.find((item) => item._id == tempSelectedData.pricing_type_variant_id) || "";
    const check_in = formData.check_in;
    const check_out = formData.check_out;
    const total_nights = formData.total_nights;

    const fetchCalculatedPrice = async () => {
      setIsPriceCalculating(true);
      try {
        const response = await fetch(url + clientpoints.booking_price_calculator, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: {
              property_id: property._id,
              room_id: room._id,
              check_in: check_in,
              check_out: check_out,
              total_nights,
              pricing_type_variant_id: pricingTypeVariant._id,
              num_of_adults: Number(tempSelectedData.guests),
              num_of_kids: Number(tempSelectedData.children),
            },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();

        setFormData((prev) => ({
          ...prev,
          total_base_price: data.Data.total_base_price,
        }));
        setTempSelectedData((prev) => ({ ...prev, total_base_price: data.Data.total_base_price }));

        setIsPriceCalculating(false);
      } catch (error) {
        toast.error(error);
      }
    };

    if (formData.property_id && tempSelectedData.room_id && tempSelectedData.pricing_type_variant_id && formData.check_in && formData.check_out && tempSelectedData.guests) {
      fetchCalculatedPrice();
    }

    // if (property._id && room._id && pricingTypeVariant._id && check_in && check_out) {
    //   fetchCalculatedPrice();
    // }
  }, [formData.check_in, formData.check_out, formData.property_id, tempSelectedData.room_id, tempSelectedData.pricing_type_variant_id, tempSelectedData.guests, tempSelectedData.children]);

  useEffect(() => {
    if (formData.tax_slab) {
      const taxPercentage = parseFloat(formData.tax_slab?.replace("%", "")) || 0;

      const taxAmount = (formData.sub_total * taxPercentage) / 100;

      setFormData((prev) => ({
        ...prev,
        tax_amount: taxAmount,
        billing_detail: {
          billing_name: "",
          billing_address: "",
          gstin: "",
          tax_type: "",
          base_amount: formData.sub_total,
          tax_amount: formData.tax_amount,
          total_amount: calculateGrandTotal(),
        },
      }));
    }
  }, [formData.sub_total, formData.tax_slab]);

  useEffect(() => {
    if (isModalOpen && quotationId) {
      setLoading(true);
      fetchData(quotationId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, quotationId]);

  console.log("rows", rows);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.quotation_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch booking data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        setDate(response_data);
        setCurrent_id(response_data._id);

        console.log("response--------", response_data);

        setFormData({
          ...response_data,
          property_id: response_data.property_id._id,
          check_in: format(response_data.check_in, "yyyy-MM-dd"),
          check_out: format(response_data.check_out, "yyyy-MM-dd"),
        });

        const existingRoomBookings = response_data.booking_details.map((item) => ({
          ...item,
          room_id: item.room_id._id,
          pricing_type_id: item.pricing_type_id._id,
          pricing_type_variant_id: item.pricing_type_variant_id._id,
          room: `${item.room_id.name} - ${item.pricing_type_id.name} (${item.pricing_type_variant_id.name})`,
        }));

        setRows(existingRoomBookings);
      }
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);

      const response = await fetch(url + clientpoints.quotation_share, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          urlName: hotelId,
          filters: { _id: current_id },
          data: {
            summaryData,
            priceSummary: {
              sub_total: formData.sub_total,
              tax_slab: formData.tax_slab,
              tax_amount: formData.tax_amount,
              grand_total: Number(formData.sub_total) + Number(formData.tax_amount),
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to share");
      }

      const responseData = await response.json();

      if (responseData.Status) {
        setIsModalOpen(false);

        toast.success(responseData.Message);
      } else {
        toast.error(responseData.Message);
        throw new Error(responseData.Message);
      }
    } catch (error) {
      console.error("error", error);
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getNextDay = (date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString().split("T")[0];
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = rows.map((row, i) =>
      i === index
        ? {
            ...row,
            [field]: value,
          }
        : row
    );
    setRows(updatedRows);
  };

  useEffect(() => {
    const bookedRoomsCount = rows.filter((row) => row.room_id === tempSelectedData.room_id).length;

    setAvailableRoomsCount(Math.max(totalRoomsCount - bookedRoomsCount, 0));
  }, [rows]);

  return (
    <>
      {isModalOpen && (
        <div className="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {mode === "view" ? `${modalName} (View)` : `${modalName} (Edit)`}
                </h5>

                <button type="button" className="btn btn-danger" onClick={handleCloseModal} style={{ marginLeft: "8px" }}>
                  <i class="ri-close-line"></i>
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div
                    className="modal-body"
                    style={{
                      maxHeight: "70vh",
                      overflowY: "scroll",
                      padding: "0 15px",
                    }}
                  >
                    <div className="">
                      {/* ------------------------------------------------------------------- */}

                      {/* --------------------------------------------------------------------- */}
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="property_id" className="col-form-label">
                            Property
                          </label>
                          <Select
                            // isDisabled={mode === "view"}
                            isDisabled={true}
                            required
                            options={properties?.map((property) => ({
                              label: `${property.title}`,
                              value: property._id,
                            }))}
                            value={selectedValues.property}
                            onChange={(val) => {
                              setFormData((prev) => ({
                                ...prev,
                                property_id: val.value,
                              }));
                              handleFieldsToTrack("property_id");
                            }}
                            placeholder="Select Property..."
                          />
                          {errors.property_id && <div className="invalid-feedback">{errors.property_id}</div>}
                        </div>

                        <div className="col-md-4">
                          <label htmlFor="check_in" className="col-form-label">
                            Check-in Date
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="date"
                            className={`form-control ${errors.check_in ? "is-invalid" : ""}`}
                            id="check_in"
                            name="check_in"
                            value={formData.check_in}
                            onChange={handleChange}
                          />
                          {errors.check_in && <div className="invalid-feedback">{errors.check_in}</div>}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="check_out" className="col-form-label">
                            Check-out Date
                          </label>
                          <input
                            disabled={mode === "view"}
                            type="date"
                            className={`form-control ${errors.check_out ? "is-invalid" : ""}`}
                            id="check_out"
                            name="check_out"
                            value={formData.check_out}
                            onChange={handleChange}
                            min={formData.check_in ? getNextDay(formData.check_in) : ""}
                          />
                          {errors.check_out && <div className="invalid-feedback">{errors.check_out}</div>}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="total_nights" className="col-form-label">
                            Total Nights
                          </label>
                          <input
                            disabled
                            type="number"
                            className={`form-control ${errors.total_nights ? "is-invalid" : ""}`}
                            id="total_nights"
                            name="total_nights"
                            value={formData.total_nights}
                            onChange={handleChange}
                          />
                          {errors.total_nights && <div className="invalid-feedback">{errors.total_nights}</div>}
                        </div>

                        <div className="container mt-4">
                          <h4>Booked Rooms</h4>
                          <table className="table table-bordered">
                            <thead className="table-light">
                              <tr>
                                <th>S.No.</th>
                                <th>Room</th>
                                <th>Qty</th>
                                <th>Adults</th>
                                <th>Children</th>
                                <th>Price/Night</th>
                                <th>Discount</th>
                                <th>Total/Night</th>
                              </tr>
                            </thead>
                            <tbody>
                              {rows.length > 0 ? (
                                rows.map((row, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{row.room}</td>
                                    <td>{row.rooms_to_book}</td>
                                    <td>{row.guests}</td>
                                    <td>{row.children}</td>
                                    <td>
                                      <input
                                        disabled={mode === "view"}
                                        type="number"
                                        className="form-control"
                                        value={row.price_per_night}
                                        onChange={(e) => handleInputChange(index, "price_per_night", e.target.value)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={mode === "view"}
                                        type="number"
                                        className="form-control"
                                        value={row.discount}
                                        onChange={(e) => handleInputChange(index, "discount", e.target.value)}
                                      />
                                    </td>
                                    <td>{Number(row.price_per_night) - Number(row.discount)}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="9" style={{ textAlign: "center" }}>
                                    No data available.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="row mt-4">
                          <h4>Customer Details</h4>
                          <div className="col-md-6">
                            <label htmlFor="user_phone_number" className="col-form-label">
                              Customer Phone Number
                            </label>
                            <input
                              disabled={mode === "view"}
                              type="tel"
                              className={`form-control ${errors.user_phone_number ? "is-invalid" : ""}`}
                              id="user_phone_number"
                              name="user_phone_number"
                              value={formData.user_phone_number}
                              onChange={handleChange}
                            />

                            {errors.user_phone_number && <div className="invalid-feedback">{errors.user_phone_number}</div>}
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="user_name" className="col-form-label">
                              Customer Name
                            </label>
                            <input
                              disabled={mode === "view"}
                              type="text"
                              className={`form-control ${errors.user_name ? "is-invalid" : ""}`}
                              name="user_name"
                              id="user_name"
                              value={formData.user_name}
                              onChange={handleChange}
                            />
                            {errors.user_name && <div className="invalid-feedback">{errors.user_name}</div>}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="user_email" className="col-form-label">
                              Customer Email
                            </label>
                            <input
                              disabled={mode === "view"}
                              type="email"
                              className={`form-control ${errors.user_email ? "is-invalid" : ""}`}
                              name="user_email"
                              id="user_email"
                              value={formData.user_email}
                              onChange={handleChange}
                            />
                            {errors.user_email && <div className="invalid-feedback">{errors.user_email}</div>}
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="special_request" className="col-form-label">
                              Special Request
                            </label>
                            <textarea
                              disabled={mode === "view"}
                              rows={4}
                              type="text"
                              className={`form-control ${errors.special_request ? "is-invalid" : ""}`}
                              name="special_request"
                              id="special_request"
                              value={formData.special_request}
                              onChange={handleChange}
                            />
                            {errors.special_request && <div className="invalid-feedback">{errors.special_request}</div>}
                          </div>
                        </div>

                        <div className="container mt-4">
                          <h4>Booking Summary</h4>
                          <div className="row">
                            <p className="col-md-3">
                              <strong>Check-In Date:</strong> {formData.check_in || "N/A"}
                            </p>
                            <p className="col-md-3">
                              <strong>Check-Out Date:</strong> {formData.check_out || "N/A"}
                            </p>
                            <p className="col-md-3">
                              <strong>Total Nights:</strong> {formData.total_nights || "N/A"}
                            </p>
                            <p className="col-md-3">
                              <strong>Adults | Children:</strong>{" "}
                              {`${rows.reduce((total, row) => total + Number(row.guests || 0), 0)} | ${rows.reduce((total, row) => total + Number(row.children || 0), 0)}`}
                            </p>
                          </div>
                          <table className="table table-bordered">
                            <thead className="table-light">
                              <tr>
                                <th>Room Details</th>
                                <th>Qty</th>
                                <th>Total Adults</th>
                                <th>Total Children</th>
                                <th>Total Price/Night</th>
                                <th>Total Price for All Nights</th>
                              </tr>
                            </thead>
                            <tbody>
                              {summaryData.length === 0 ? (
                                <tr>
                                  <td colSpan="7" style={{ textAlign: "center" }}>
                                    No data available. Please select a room!
                                  </td>
                                </tr>
                              ) : (
                                summaryData.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.roomDetails}</td>
                                    <td>{item.qty}</td>
                                    <td>{item.totalAdults}</td>
                                    <td>{item.totalChildren}</td>
                                    <td>{item.totalPricePerNight}</td>
                                    <td>{item.totalPriceForAllNights}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className="mt-4 row">
                          <div className="mb-3 col-md-3">
                            <label htmlFor="subTotal" className="form-label">
                              Sub-Total
                            </label>
                            <input disabled={mode === "view"} type="number" className="form-control" id="subTotal" value={formData.sub_total} readOnly />
                          </div>
                          <div className="mb-3 col-md-3">
                            <label htmlFor="taxSlabDropdown" className="form-label">
                              Select Tax Slab:
                            </label>
                            <select disabled={mode === "view"} id="taxSlabDropdown" className="form-select" name="tax_slab" value={formData.tax_slab} onChange={handleChange}>
                              <option value="0%">NA</option>
                              <option value="5%">5%</option>
                              <option value="12%">12%</option>
                              <option value="18%">18%</option>
                              <option value="28%">28%</option>
                            </select>
                          </div>
                          <div className="mb-3 col-md-3">
                            <label htmlFor="tax_amount" className="form-label">
                              Tax Amount
                            </label>
                            <input
                              disabled={mode === "view"}
                              readOnly
                              type="number"
                              className="form-control"
                              id="tax_amount"
                              value={formData.tax_amount}
                              onChange={(e) => setFormData((prev) => ({ ...prev, tax_amount: Number(e.target.value) }))}
                            />
                          </div>
                          <div className="mb-3 col-md-3">
                            <label htmlFor="grandTotal" className="form-label">
                              Grand Total
                            </label>
                            <input disabled={mode === "view"} type="number" className="form-control" id="grandTotal" value={calculateGrandTotal()} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="modal-footer d-flex justify-content-between align-items-center">
                  <p className="float-start">CreatedAt : {date.createdAt ? new Date(date.createdAt).toLocaleDateString() : "-"}</p>
                  <div className="d-flex gap-2">
                    <>
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        {isSubmitting ? "Loading..." : "Share"}
                      </button>
                    </>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

export const bookingStatus = ["pending", "completed", "cancelled"];
export const rateCategories = ["Hot Deal", "Best Available Rate"];
export const paymentModes = ["UPI", "Card", "Net Banking"];
export const cancellationTypes = ["nonRefundable", "refundable"];

const initialFormData = {
  user_phone_number: "",
  // area: "",
  property_id: "",
  room_id: "",
  pricing_type_id: "",
  check_in: "",
  check_out: "",
  total_nights: 0,
  guests: 1,
  children: 0,
  guest_name: "",
  phone: "",
  email: "",
  special_request: "",
  payment_mode: paymentModes[0],
  cancellation_type: cancellationTypes[0],
  deal_type: rateCategories[0],
  total_base_price: 0,
  discount_value: 0,
  discount_type: "",
  promo_discount_value: "",
  promo_code: "",
  tax_amount: 0,
  total_cost: 0,
  status: bookingStatus[0],
  billing_detail: {
    billing_name: "",
    billing_address: "",
    gstin: "",
    tax_type: "",
    base_amount: 0,
    tax_amount: 0,
    total_amount: 0,
  },
};

import React, { useState, useEffect, useContext, useRef } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import useHotelId from "../../../../../../lib/useHotelId.js";

const Component = ({ disabled, userName, setUserName, view = "add", searchValueProps, suggestionsProps, showDropdownProps, form = true }) => {
  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { searchValueUser, setSearchValueUser } = searchValueProps;
  const { suggestionsUser, setSuggestionsUser } = suggestionsProps;
  const { showDropdownUser, setShowDropdownUser } = showDropdownProps;
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  let [countIndex, setCountIndex] = useState(0);

  const loadOptions = async () => {
    try {
      const response = await fetch(url + clientpoints.user_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ search: searchValueUser, urlName: hotelId }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestionsUser(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1); // State variable to track current page
  const [perPage, setPerPage] = useState(10); // State variable to track entries per page
  const [totalPages, setTotalPages] = useState(0); // State variable to track total pages

  const fetchDataForPage = async (page) => {
    try {
      const response = await fetch(url + clientpoints.user_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({ search: "", page: page, size: perPage, sorting: { createdAt: "1" } }), // Fetch data for specific page
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const newData = responseData.Data;

      setSuggestionsUser((prevSuggestions) => [...prevSuggestions, ...newData]);
      setTotalPages(Math.ceil(responseData.totalCount / perPage)); // Calculate total pages
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleScroll = async (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      const nextPageCount = currentPage + 1;

      if (nextPageCount <= totalPages) {
        setCurrentPage(nextPageCount);
        fetchDataForPage(nextPageCount);
      }
    }
  };

  const loadOptionsOnClick = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.user_view, {
        method: "POST",
        headers: pageHeaders,
        // body: JSON.stringify({ search: "", page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
        body: JSON.stringify({ search: "", filters: {}, page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch options");
      }
      const responseData = await response.json();
      const data = responseData.Data;
      setSuggestionsUser(data);
      setTotalPages(responseData.totalCount);
      setSearchValueUser("");
    } catch (error) {
      console.error("Error fetching options:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadMatchedOption = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(url + clientpoints.user_view, {
          method: "POST",
          headers: pageHeaders,
          // body: JSON.stringify({ search: "", page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
          body: JSON.stringify({ search: "", filters: { _id: userName }, page: countIndex + 1, sorting: { createdAt: "1" } }), // Increment countIndex by 1
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch options");
        }
        const responseData = await response.json();
        const data = responseData.Data;

        const { name, _id } = data[0];

        setSearchValueUser(name);
        // setUserName(_id);
        setShowDropdownUser(name);
        // setSuggestionsUser([]);
      } catch (error) {
        console.error("Error fetching options:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (userName && view === "edit") {
      loadMatchedOption();
    }
  }, [userName]);

  const handleOptionClick = (selectedOption) => {
    if (selectedOption) {
      const { name, _id } = selectedOption;
      setSearchValueUser(name);
      setUserName(_id);
      setShowDropdownUser(name);
      setSuggestionsUser([]);
    } else if (selectedOption == "") {
      setSearchValueUser("All Users");
      setShowDropdownUser("All Users");
      setUserName("");
      setSuggestionsUser([]);
    } else {
      setSearchValueUser("");
      setShowDropdownUser("");
      setUserName("");
      setSuggestionsUser([]);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setSuggestionsUser([]);
        setCurrentPage(1);
        setPerPage(10);
        setTotalPages(0);
        setSearchValueUser(showDropdownUser);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdownUser]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <i className="ri-arrow-down-s-line ab1" onClick={loadOptionsOnClick}></i>
      <input
        disabled={disabled}
        autoComplete="off"
        ref={inputRef}
        placeholder={form ? "Search for User..." : "Select User"}
        onChange={(e) => setSearchValueUser(e.target.value)}
        value={searchValueUser}
        onKeyUp={() => loadOptions(searchValueUser)}
        onClick={loadOptionsOnClick}
        id="userName"
        className="search-input form-control customSelect w-100"
      />
      {isLoading ? (
        <div className="spinner-border text-primary my_spin" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        suggestionsUser?.length > 0 && (
          <ul id="dropdownMenu" onScroll={handleScroll} style={{ overflowY: "scroll", height: "200px" }} className="dropdown-menu mydrop_hgt">
            {form ? (
              <li onClick={() => handleOptionClick("")}>
                All Users<span className="hidden" data-client-id={""}></span>
              </li>
            ) : (
              ""
            )}
            {suggestionsUser?.length > 0 ? (
              suggestionsUser?.map((user) => (
                <li key={user._id} onClick={() => handleOptionClick(user)}>
                  {user.name} {user.name && user.email ? `( ${user.email} ) ` : ""}
                  <span className="hidden" data-user-id={user._id} data-client-id={user.client_id ? user.client_id._id : ""}></span>
                </li>
              ))
            ) : (
              <li>No data found</li>
            )}
          </ul>
        )
      )}
    </div>
  );
};

export default Component;

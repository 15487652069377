import { useState, useEffect, useRef, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import useScrollBlock from "../../../utils/useScrollBlock";
import { useLocation, useParams, useNavigate, json } from "react-router-dom";
import "../../../../nav/components/location/component.css";
import { url, clientpoints, getDecryptedDataFromLocalStorage, whoAmIHeaders, client_prefix } from "../../../../../lib/lib";
import { AuthUserContext } from "../../../../../lib/AuthUserContext";
import useHotelId from "../../../../../lib/useHotelId";

function Component() {
  const navigate = useNavigate();

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [stateDisabled, setStateDisabled] = useState(false);
  const [stateDisabledForMail, setStateDisabledForMail] = useState(false);
  const [setIfErrorOccure, SetIfErrorOccure] = useState(false);
  
  const handleCheckboxChange = (e) => { setStateDisabledForMail(e.target.checked); };
  const handleCheckboxChangeIfError = (e) => { SetIfErrorOccure(e.target.checked); };

  useEffect(() => {
    if (typeTeamRoleObject.hotel) {
      if (typeTeamRoleObject?.hotel?.edit) {
        setStateDisabled(true);
      } else {
        setStateDisabled(false);
      }
    } else {
      setStateDisabled(true);
    }
  }, [typeTeamRoleObject]);


  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 
  const [hotelData, setHotelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUpdate, setLoadingUpdate] = useState(false);

  const fetchHotelData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(url + clientpoints.hotel_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          urlName: hotelId,
        }),
      });

      // Check if request was successful
      if (response.ok) {
        // Parse response JSON
        const data = await response.json();
        // Store fetched data in state
        setHotelData(data.Data[0]);
        setStateDisabledForMail(data.Data[0]?.mail_status);
        SetIfErrorOccure(data.Data[0]?.error_status);
        setIsLoading(false); // Update loading state
      } else {
        // Handle error if request fails
        throw new Error("Failed to fetch hotel data");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch hotel data");
      setIsLoading(false); // Update loading state
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHotelData();
  }, []);

  const [formData, setFormData] = useState({
    hotel_name: '',
    phone: '',
    email: '',
    description: '',
    billing_detail: {
      legal_company: '',
      billing_address: '',
      gstn: '',
      pan: '',
      cin: '',
      tan: ''
    },
    mail_detail: {
      smtp_email: '',
      smtp_password: '',
    },
    profile: null,
  });
  
  const handleFileChange = (e) => {
    setFormData({ ...formData, profile: e.target.files[0] });
  };

  useEffect(() => {
    if (hotelData) {
      setFormData((prevState) => ({
        ...prevState,
        hotel_name: hotelData.hotel_name || "",
        billing_detail: {
          legal_company: hotelData.billing_detail?.legal_company || "",
          billing_address: hotelData.billing_detail?.billing_address || "",
          gstn: hotelData.billing_detail?.gstn || "",
          pan: hotelData.billing_detail?.pan || "",
          cin: hotelData.billing_detail?.cin || "",
          tan: hotelData.billing_detail?.tan || "",
        },
        mail_detail:{
          smtp_email: hotelData.mail_detail?.smtp_email || "",
          smtp_password: hotelData.mail_detail?.smtp_password || "",
        },
        phone: hotelData.phone || "",
        email: hotelData.email || "",
        description: hotelData.description || "",
      }));
    }
  }, [hotelData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name.startsWith("billing_detail.")) {
      const fieldName = name.split(".")[1];
      setFormData((prevState) => ({
        ...prevState,
        billing_detail: {
          ...prevState.billing_detail,
          [fieldName]: value,
        },
      }));
    } 
    
    else if (name.startsWith("mail_detail.")) {
      const fieldName = name.split(".")[1]; 
      setFormData((prevState) => ({
        ...prevState,
        mail_detail: {
          ...prevState.mail_detail,
          [fieldName]: value,
        },
      }));
    } 

    else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingUpdate(true);
    let profileUrl = null;
    let profileName = null;
    if (formData.profile) {
      const profileFormData = new FormData();
      profileFormData.append('file', formData.profile);  // Assuming formData.profile is the file
      profileFormData.append('urlName', hotelId);  // Add other form data
      profileFormData.append('id', hotelData._id);  // Add other form data

      try {
        const imageResponse = await fetch(url + clientpoints.hotel_image, {
          method: 'POST',
          headers: {
            type: "client",
            authorization: "$^CLiENt$%^&I&^@4567",
            key: "jsjdj744%5GSHr45",
          },  
          credentials: 'include',
          body: profileFormData,  // Directly pass FormData, don't stringify
        });

        const imageData = await imageResponse.json();
        if (imageResponse.ok) {
          profileUrl = imageData.imageUrl;
          profileName = imageData.fileName;
          setHotelData(prevData => ({
            ...prevData,
            img: profileName,
            img_url: profileUrl,
          }));
        } else {
          alert('Error uploading image: ' + imageData.message);
          return;
        }
      } catch (error) {
        console.error(error);
        alert('Error uploading image');
        return;
      }

    }
  
    // Prepare the hotel data to send
    const formDataToSend = {
      hotel_name: formData.hotel_name,
      phone: formData.phone,
      email: formData.email,
      description: formData.description,
      billing_detail: {
        legal_company: formData.billing_detail.legal_company,
        billing_address: formData.billing_detail.billing_address,
        gstn: formData.billing_detail.gstn,
        pan: formData.billing_detail.pan,
        cin: formData.billing_detail.cin,
        tan: formData.billing_detail.tan
      },
      mail_detail: {
        smtp_email : formData.mail_detail.smtp_email,
        smtp_password : formData.mail_detail.smtp_password,
      },
      mail_status: stateDisabledForMail ? true : false,
      error_status: stateDisabledForMail ? setIfErrorOccure : false,
    };

    try {
      const response = await fetch(url + clientpoints.hotel_edit, {
        method: 'POST',
        headers: pageHeaders,
        credentials: 'include',
        body: JSON.stringify({ urlName: hotelId, data: formDataToSend }), 
      });
  
      const data = await response.json();
      if (response.ok) {
        toast.success("Hotel data updated successfully");
      } else {
        throw new Error("Failed to update hotel data");
      }
      fetchHotelData();
      setLoadingUpdate(true);
    } catch (error) {
      console.error(error);
      setLoadingUpdate(true);
      toast.error("Failed to update hotel data");
    }finally{
      setLoadingUpdate(false);
    }
  };

  const handleRefresh = () => {
    fetchHotelData();
  };

  const deleteImage = async () => {
    const deleteConfirm = confirm("Do you want to delete this image?");
    if (deleteConfirm) 
      {
        try {
          const response = await fetch(url + clientpoints.hotel_image_delete, {
            method: "POST",
            headers: pageHeaders,
            credentials: "include",
            body: JSON.stringify({
              urlName: hotelId,
              // filters: { _id: deleteItemId },
              data:{img: hotelData.img}
            }),
          });
          const responseData = await response.json();
    
          if (!response.ok) {
            throw new Error("Failed to delete item");
          }
    
          if (responseData.Status) {
            toast.success(responseData.Message);
          } else {
            toast.error(responseData.Message);
          }

          fetchHotelData();

        } catch (error) {
          console.error("Error deleting item:", error);
        }
        
      }
  };

  return (
    <>
      <ToastContainer />
      <div className="inner-padding staff-table-parent">
        <div className="featured-flx">
          <div className="featured-flx1">
            <p className="main_heading1">Hotel Profile</p>
          </div>

          <div className="featured-flx1">
            <button className="circle-btn-add icon" type="button" onClick={handleRefresh}>
              {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="ri-refresh-line"></i>}
            </button>
          </div>
        </div>

        <div className="row">
          {isLoading ? (
            <p>Loading...</p>
          ) : hotelData ? (
            <>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="family">Hotel Name*</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="hotel_name"
                      value={formData.hotel_name}
                      onChange={handleChange}
                      placeholder="Enter  Hotel Name"
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="family">Phone*</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter  Phone number"
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="family">Email*</label>
                    <input type="email" className="form-control family" name="email" value={formData.email} onChange={handleChange} placeholder="Enter Email" required disabled={!stateDisabled} />
                  </div>
                </div>
              </div>
              <div className="row w-100 mb-4">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="family">Description*</label>
                    <textarea
                      className="form-control family"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Enter  Description"
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
              </div>

              <div className="featured-flx1">
                <p className="main_heading1">Billing Details</p>
              </div>

              <div className="row w-100 mt-4">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="family">Legal Company</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="billing_detail.legal_company"
                      value={formData.billing_detail.legal_company}
                      onChange={handleChange}
                      placeholder="Enter legal company"
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="family">Billing Address</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="billing_detail.billing_address"
                      value={formData.billing_detail.billing_address}
                      onChange={handleChange}
                      placeholder="Enter billing address"
                      required
                      disabled={!stateDisabled}
                    />{" "}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="family">Gstn</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="billing_detail.gstn"
                      value={formData.billing_detail.gstn}
                      onChange={handleChange}
                      placeholder="Enter gstn"
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
              </div>
              <div className="row w-100 mb-4">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="family">Pan</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="billing_detail.pan"
                      value={formData.billing_detail.pan}
                      onChange={handleChange}
                      placeholder="Enter pan"
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="family">Cin</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="billing_detail.cin"
                      value={formData.billing_detail.cin}
                      onChange={handleChange}
                      placeholder="Enter Cin"
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="family">Tan</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="billing_detail.tan"
                      value={formData.billing_detail.tan}
                      onChange={handleChange}
                      placeholder="Enter tan"
                      required
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
              </div>

              <div className="featured-flx1">
                <p className="main_heading1">
                  <input type="checkbox" className="form-check-input" id="enableFieldsCheckbox" checked={stateDisabledForMail} onChange={handleCheckboxChange} />  Mail Details
                </p>
              </div>

              <div className="row w-100 mt-4 mb-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="family">SMTP Email</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="mail_detail.smtp_email"
                      value={formData.mail_detail.smtp_email}
                      onChange={handleChange}
                      placeholder="Enter smtp email"
                      required
                      disabled={!stateDisabled || !stateDisabledForMail}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="family">SMTP Password</label>
                    <input
                      type="text"
                      className="form-control family"
                      name="mail_detail.smtp_password"
                      value={formData.mail_detail.smtp_password}
                      onChange={handleChange}
                      placeholder="Enter smtp password"
                      required
                      disabled={!stateDisabled || !stateDisabledForMail}
                    />
                  </div>
                </div>

                {stateDisabledForMail &&(
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-check d-flex align-items-center">
                        <input type="checkbox" className="form-check-input mt-3" id="enableFieldsCheckboxIfError" checked={setIfErrorOccure} onChange={handleCheckboxChangeIfError} />
                        <label className="form-check-label ms-2" htmlFor="enableFieldsCheckboxIfError">
                          Please confirm the fields. If an error occurs, the email will be sent through StayStation.
                        </label>
                      </div>
                    </div>
                  </div>
                )}

              </div>

              <div className="row w-100 mb-4">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="family">Profile</label>
                    <input
                      type="file"
                      className="form-control family"
                      onChange={handleFileChange}
                      disabled={!stateDisabled}
                    />
                  </div>
                </div>
              </div>

              {hotelData.img && (
                <>
                {/* <img src={hotelData.img_url} alt="Hotel Profile" className="img-fluid" />
                <butoon onClick={deleteImage} className="btn btn-danger mt-2 float-right">Delete Image</butoon>
                <br></br> */}
                <div className="position-relative">
                  <img
                    src={hotelData.img_url}
                    alt="Hotel Profile"
                    className="img-fluid"
                  />
                  <button
                    onClick={deleteImage}
                    className="btn btn-danger position-absolute top-0 end-0 m-2 rounded-circle"
                    style={{ fontSize: '18px' }}
                  >
                    <i class="ri-delete-bin-6-line"></i>
                  </button>
                </div>
                </>
              )}
              {whoAmI === "team" ? (
                <>
                  {typeTeamRoleObject?.hotel?.edit && (
                    <button type="submit" className="btn btn-primary mt-4 mb-4">
                      {isLoadingUpdate ? (<span>Loading...</span>) : ("Update")}
                    </button>
                  )}
                </>
              ) : (
                <>
                  <button type="submit" className="btn btn-primary mt-4 mb-4">
                  {isLoadingUpdate ? (<span>Loading...</span>) : ("Update")}
                  </button>
                </>
              )}
            </form>
            </>
          ) : (
            <p>No data found</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Component;

import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

import "./component.css";
import { prefix } from "@fortawesome/free-brands-svg-icons";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, view = "view" }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const initialFormData = {
    name: "",
    // image: "",
    meta_data: {
      title: "",
      description: "",
      keywords: "",
    },
    suffix: "",
    prefix: "",
    increment_type: "Numeric",
    isActive: false,
    isFeatured: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [mode, setMode] = useState(view);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleChangeMetaInput = (e) => {
    setFormData((prev) => ({
      ...prev,
      meta_data: { ...prev.meta_data, [e.target.name]: e.target.value },
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let room_typeId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    // if (!formData.prefix.trim()) {
    //   errors.prefix = "Prefix is required";
    //   isValid = false;
    // }

    // if (!formData.suffix.trim()) {
    //   errors.suffix = "Suffix is required";
    //   isValid = false;
    // }

    if (!formData.increment_type.trim()) {
      errors.increment_type = "Increment type is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen && room_typeId) {
      setLoading(true);
      fetchData(room_typeId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, room_typeId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.room_type_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(client_prefix);
      }

      const data = await response.json();

      console.log("dataaa", data);

      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];

        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          name: response_data.name,
          meta_data: {
            title: response_data.meta_data.title,
            description: response_data.meta_data.description,
            keywords: response_data.meta_data.keywords,
          },
          suffix: response_data.suffix || "",
          prefix: response_data.prefix || "",
          increment_type: response_data.increment_type || "",
          isActive: response_data.isActive,
          isFeatured: response_data.isFeatured,
        });
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.room_type_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: current_id },
            data: {
              name: formData.name,
              meta_data: {
                title: formData.meta_data.title,
                description: formData.meta_data.description,
                keywords: formData.meta_data.keywords,
              },
              suffix: formData.suffix,
              prefix: formData.prefix,
              increment_type: formData.increment_type,
              isActive: formData.isActive,
              isFeatured: formData.isFeatured,
            },
          }),
        });

        if (!response.ok) {
          navigate(client_prefix);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {mode === "view" ? `${modalName} (View)` : `${modalName} (Edit)`}{" "}
                </h5>
                {mode === "view" && (
                  <button className="ms-auto btn btn-primary" onClick={() => setMode("edit")}>
                    <i class="ri-edit-box-line"></i>
                  </button>
                )}
                <button type="button" className="btn btn-danger" onClick={handleCloseModal} style={{ marginLeft: "8px" }}>
                  <i class="ri-close-line"></i>
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="modal-body text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body" style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="name" className="col-form-label">
                          Name
                        </label>
                        <input
                          disabled={mode === "view"}
                          type="text"
                          className={`form-control ${errors.name ? "is-invalid" : ""}`}
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                      </div>

                      {/* <div className="col-md-6">
                        <label htmlFor="title" className="col-form-label">
                          Meta Title
                        </label>
                        <input
                          disabled={mode === "view"}
                          type="text"
                          className={`form-control `}
                          id="title"
                          name="title"
                          value={formData.meta_data.title}
                          onChange={handleChangeMetaInput}
                        />
                      </div> */}

                      {/* <div className="col-md-6">
                        <label htmlFor="description" className="col-form-label">
                          Meta Description
                        </label>
                        <input
                          disabled={mode === "view"}
                          type="text"
                          className={`form-control `}
                          id="description"
                          name="description"
                          value={formData.meta_data.description}
                          onChange={handleChangeMetaInput}
                        />
                      </div> */}

                      {/* <div className="col-md-6">
                        <label htmlFor="keywords" className="col-form-label">
                          Meta Keywords
                        </label>
                        <input
                          disabled={mode === "view"}
                          type="text"
                          className={`form-control `}
                          id="keywords"
                          name="keywords"
                          value={formData.meta_data.keywords}
                          onChange={handleChangeMetaInput}
                        />
                      </div> */}

                      <div className="col-md-6">
                        <label htmlFor="prefix" className="col-form-label">
                          Room Number Prefix
                        </label>
                        <input
                          disabled={mode === "view"}
                          placeholder="4"
                          type="text"
                          className={`form-control ${errors.prefix ? "is-invalid" : ""}`}
                          id="prefix"
                          name="prefix"
                          value={formData.prefix}
                          onChange={handleChange}
                        />
                        {errors.prefix && <div className="invalid-feedback">{errors.prefix}</div>}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="suffix" className="col-form-label">
                          Room Number Suffix
                        </label>
                        <input
                          disabled={mode === "view"}
                          placeholder="A"
                          type="text"
                          className={`form-control ${errors.suffix ? "is-invalid" : ""}`}
                          id="suffix"
                          name="suffix"
                          value={formData.suffix}
                          onChange={handleChange}
                        />
                        {errors.suffix && <div className="invalid-feedback">{errors.suffix}</div>}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="increment_type" className="col-form-label">
                          Type
                        </label>
                        <select
                          disabled={mode === "view"}
                          id="increment_type"
                          className={`form-select ${errors.increment_type ? "is-invalid" : ""}`}
                          value={formData.increment_type}
                          onChange={(e) => setFormData((prev) => ({ ...prev, increment_type: e.target.value }))}
                        >
                          <option value="Numeric">Numeric</option>
                          <option value="Alphabet">Alphabet</option>
                        </select>
                        {errors.increment_type && <div className="invalid-feedback">{errors.increment_type}</div>}
                      </div>

                      {/* <div className="d-flex align-items-center gap-3">
                        <label>
                          <input
                            disabled={mode === "view"}
                            type="checkbox"
                            name="isFeatured"
                            checked={formData.isFeatured}
                            onChange={(e) => setFormData((prev) => ({ ...prev, isFeatured: e.target.checked }))}
                          />
                        </label>
                        Featured
                      </div> */}

                      {/* <div className="d-flex align-items-center gap-3">
                        <label>
                          <input
                            disabled={mode === "view"}
                            type="checkbox"
                            name="isActive"
                            checked={formData.isActive}
                            onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))}
                          />
                        </label>
                        Active
                      </div> */}
                    </div>
                  </div>
                )}
                <div className="modal-footer d-flex justify-content-between align-items-center">
                  <p className="float-start">CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className="d-flex gap-2">
                    {mode !== "view" && (
                      <>
                        {/* <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                          Close
                        </button> */}
                        {/* <button type="submit" className="btn btn-primary">Update</button> */}
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                          {isSubmitting ? "Loading..." : "Update"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

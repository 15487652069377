import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import Select from "react-select";

import "./component.css";
import useHotelId from "../../../../../../lib/useHotelId.js";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh, role = [], property = [] }) {
  // const { hotelId } = useParams();
  const hotelId = useHotelId(); 

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    password: "",
    role_id: "",
    property_id: [],
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    setErrors(errors);
    return isValid;
  };

  const [selectValues, setSelectValues] = useState({
    role_id: "",
    property_id: "",
  });

  useEffect(() => {
    const roleObj = role.find((item) => item.value === formData.role_id);
    const role_id = roleObj ? roleObj : "";
    setSelectValues((prev) => ({ ...prev, role_id }));
  }, [formData.role_id]);

  useEffect(() => {
    const propertyObj = property.find((item) => item.value === formData.property_id);
    const property_id = propertyObj ? propertyObj : "";
    setSelectValues((prev) => ({ ...prev, property_id }));
  }, [formData.property_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.team_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            ...formData,
          }),
        });
        setFormData(initialFormData);
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };
  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body' style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                  <div className='row'>
                    <label className='col-12 mb-3'>
                      Role Type
                      <Select
                        options={role}
                        value={role.find((option) => option.value === (formData.role_id && formData.role_id._id))}
                        onChange={(val) => setFormData((prev) => ({ ...prev, role_id: val.value }))}
                        placeholder='Select Role...'
                        className='flex-1 w-full'
                        styles={customStyles}
                        menuPortalTarget={document.body}
                      />
                    </label>
                    <label className='col-12 mb-3'>
                      Property Type
                      <Select
                        options={property}
                        closeMenuOnSelect={false}
                        isMulti
                        value={formData.property_id.map((id) => property.find((loc) => loc.value === id || loc.value === id._id))}
                        onChange={(selectedOptions) => setFormData((prev) => ({ ...prev, property_id: selectedOptions.map((option) => option.value) }))}
                        placeholder='Select Property...'
                        styles={customStyles}
                        menuPortalTarget={document.body}
                      />
                    </label>
                    <label className='col-md-6 mb-3'>
                      Name
                      <input type='text' name='name' value={formData.name} onChange={handleChange} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                    </label>
                    <label className='col-md-6 mb-3'>
                      Email
                      <input type='text' name='email' value={formData.email} onChange={handleChange} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                    </label>
                    <label className='col-md-6 mb-3'>
                      Phone
                      <input type='text' name='phone' value={formData.phone} onChange={handleChange} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                    </label>
                    <label className='col-md-6 mb-3'>
                      Password
                      <input type='text' name='password' value={formData.password} onChange={handleChange} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                    </label>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};
